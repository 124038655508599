/* eslint-disable */
import { getEl, VALIDATORS } from "./validators";

export const initValidation = (config) => ({
    lastName: [{ fn: () => VALIDATORS.required(getEl("lastName")), message: config?.fields?.lastName?.required }],
    firstName: [{ fn: () => VALIDATORS.required(getEl("firstName")), message: config?.fields?.firstName?.required }],
    emailAddress: [
        { fn: () => VALIDATORS.required(getEl("emailAddress")), message: config?.fields?.emailAddress?.required },
        { fn: () => VALIDATORS.email(getEl("emailAddress")), message: config?.fields?.emailAddress?.invalid },
    ],
    confirmEmailAddress: [
        { fn: () => VALIDATORS.required(getEl("confirmEmailAddress")), message: config?.fields?.confirmEmailAddress?.required },
        { fn: () => VALIDATORS.email(getEl("confirmEmailAddress")), message: config?.fields?.confirmEmailAddress?.invalid },
        { fn: VALIDATORS.emailMatch, message: config?.fields?.confirmEmailAddress?.match },
    ],
    zipCode: [
        { fn: () => VALIDATORS.required(getEl("zipCode")), message: config?.fields?.zipCode?.required },
        { fn: () => VALIDATORS.zipCode(getEl("zipCode")), message: config?.fields?.zipCode?.invalid },
    ],
    city: [
        { fn: () => VALIDATORS.required(getEl("city")), message: config?.fields?.city?.required },
        { fn: () => VALIDATORS.city(getEl("city")), message: config?.fields?.city?.invalid },
    ],
    phoneNumber: [
        { fn: () => VALIDATORS.required(getEl("phoneNumber")), message: config?.fields?.phoneNumber?.required },
        { fn: () => VALIDATORS.phoneNumber(getEl("phoneNumber")), message: config?.fields?.phoneNumber?.invalid },
    ],
    address: [
        { fn: () => VALIDATORS.required(getEl("address")), message: config?.fields?.address?.required },
        { fn: () => VALIDATORS.address(getEl("address")), message: config?.fields?.address?.invalid },
    ],
    password: [
        { fn: () => VALIDATORS.required(getEl("password")), message: config?.fields?.password?.required },
        { fn: () => VALIDATORS.password(getEl("password")), message: config?.fields?.password?.invalid },
    ],
    confirmPassword: [
        { fn: () => VALIDATORS.required(getEl("confirmPassword")), message: config?.fields?.confirmPassword?.required },
        { fn: () => VALIDATORS.password(getEl("password")), message: config?.fields?.confirmPassword?.invalid },
        { fn: VALIDATORS.passwordMatch, message: config?.fields?.confirmPassword?.match },
    ],
    serialNumber: [
        { fn: () => VALIDATORS.required(getEl("serialNumber")), message: config?.fields?.serialNumber?.required },
        { fn: () => VALIDATORS.serialNumber(getEl("serialNumber")), message: config?.fields?.serialNumber?.invalid },
    ],
    month: [{ fn: () => VALIDATORS.required(getEl("month")), message: config?.fields?.month?.required }],
    year: [{ fn: () => VALIDATORS.required(getEl("year")), message: config?.fields?.year?.required }],
    termsAndConditions: [{ fn: VALIDATORS.checked, message: config?.fields?.termsAndConditions?.required }],
    newsletter: [{ fn: VALIDATORS.newsletter, message: config?.fields?.newsletter?.required }],
    deviceType: [{ fn: VALIDATORS.deviceType, message: config?.fields?.deviceType?.required }],
});
