const pathname = window.location.pathname;
const linkChecker = /\/medical[^0-9a-z_-]|\/medical$/;
const modalBtns = document.getElementById("medicalBtnContainer");

const func = {
    init() {
        const anchors = document.querySelectorAll("a");

        anchors.forEach(anchor => {
            if (anchor.closest(".modal__parent")) return false;
            
            // Scan all anchors that consist a /medical/ or /medical in its URL including richtext anchors
            const { href, pathname, host } = anchor;
            const modalOpen = linkChecker.test(pathname) && host === location.host;
            if (modalOpen) {
                const id = modalBtns.dataset.id;
                anchor.onclick = e => {
                    e.preventDefault();
                    this.setBtnLink(href);
                    this.openModal(id);
                };
            }
        });
    },
    /**
     * setBtnLink
     * Set the modal button link using dispatch event
     * @param link
     */
    setBtnLink(link) {
        this.eventDispatch("set-url", { link });
    },
    /**
     * openModal
     * Function to open the medical modal
     */
    openModal(id) {
        this.eventDispatch("open-modal", { id });
    },
    /**
     * eventDispatch
     * Function to dispatch custom events
     * @param event 
     * @param param 
     */
    eventDispatch(event, param) {
        dispatchEvent(new CustomEvent(event, { detail: param }));
    }
};

const init = () => {
    // Check if the page is not part of the medical page
    if (modalBtns && !linkChecker.test(pathname)) func.init();
};

export default { init };