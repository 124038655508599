import Cookies from "js-cookie";

function disableRememberMe() {
    const rememberMeTokenCookieName     = "x-sny-refresh";
    const rememberMeExpiration          = "x-sny-remembermeexp";
    const isRememberMeEnabledCookieName = "x-sny-isrememberme";

    const isRememberMeCookie = Cookies.get(isRememberMeEnabledCookieName);
    if ("true" === isRememberMeCookie) {
        Cookies.set(isRememberMeEnabledCookieName, "loggedout");
        Cookies.remove(rememberMeTokenCookieName);
        Cookies.remove(rememberMeExpiration);
    }
}
function init() {
    const logoutIcon = document.querySelectorAll(".logoutIcon");
    if (logoutIcon) {
        logoutIcon.forEach(function(logout) {
            logout.addEventListener("click", function (e) {
                e.preventDefault();

                disableRememberMe();

                if (window.janrain) {
                    window.janrain.capture.ui.endCaptureSession();
                } else {
                    let url = location.href;
                    let hash = window.location.hash;

                    if (hash) {
                        url = url.substring(0, url.lastIndexOf('#'));
                    }

                    url += (url.split("?")[1] ? "&" : "?") + "mgnlLogout=true";
                    window.location.href = url + hash;
                }
                localStorage.removeItem("cart");
            });

        });
    }
}

export default { init };
