import Cookies from "js-cookie";
import { removeElementById } from "../../helpers/util";
import Alpine from "alpinejs";

/* eslint-disable */

Alpine.data("siteEntry", (config) => {
    const opts = { ...config };
    return {
        opts,
        setStorage(name, value, options = {}) {
            switch (opts.storageType) {
                case "session":
                    this.setSessionStorage(name, value);
                    break;
                case "local":
                    this.setLocalStorage(name, value);
                    break;
                default:
                    this.setCookie(name, value, (options = {}));
            }
        },
        setCookie(name, value, options = {}) {
            return Cookies.set(name, value, (options = {}));
        },
        setSessionStorage(name, value) {
            return window.sessionStorage.setItem(name, value);
        },
        setLocalStorage(name, value) {
            return window.localStorage.setItem(name, value);
        },
        closeModal(denyURL = null) {
            if (denyURL) {
                window.open(denyURL, "_self");
            } else {
                removeElementById("siteEntryModal");
            }
        },
    };
});
