import { trackEvent } from "../../track";
import Cookies from "js-cookie";
import Alpine from "alpinejs";
import showPassword from "../../showPassword";

let validator;
const form = document.querySelector(".jsValidate");

if (form) {
    try {
        import("validator/validator").then((module) => {
            validator = module.default;
        });
    } catch (error) {
        console.log(error.message); // eslint-disable-line no-console
    }
}

/* eslint-disable */
const validators = {
    checkbox: {
        required: (el) => el.checked,
    },
    select: {
        required: (el) => el.value !== "",
    },
    string: {
        required: (el) => !validator.isEmpty(el.value),
        email: (el) => validator.isEmail(el.value),
        emailNotBlacklisted: (el) => !(document.querySelector("input[data-schema='email']").getAttribute("blacklisted-domains").indexOf(el.value.split("@").pop()) > -1),
        password: (el) => validator.isStrongPassword(el.value, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 0 }),
        passwordMatch: (el) => validator.equals(el.value, document.querySelector("input[data-schema='password']").value),
        phone: (el) => (validator.isEmpty(el.value) ? true : validator.isMobilePhone(el.value)),
        minMaxLen: (el) => validator.isLength(el.value, { min: el.dataset.min, max: el.dataset.max }),
        minLen: (el) => validator.isLength(el.value, { min: el.dataset.min }),
        maxLen: (el) => validator.isLength(el.value, { max: el.dataset.max }),
        number: (el) => validator.isInt(el.value),
    },
};

const dataFN = ({ errorMessages, ...settings }) => {
    const config = Object.assign(
        {
            errorMessages: {
                default: errorMessages?.default ? errorMessages.default : "Invalid input",
                required: errorMessages?.required ? errorMessages.required : "This field is required",
                email: errorMessages?.email ? errorMessages.email : "Please enter an email",
                emailNotBlacklist: errorMessages?.emailNotBlacklist ? errorMessages.emailNotBlacklist : "This email is not valid",
                phone: errorMessages?.phone ? errorMessages.phone : "Please enter a valid phone number",
                minMaxLen: errorMessages?.minMaxLen ? errorMessages.minMaxLen : "Please enter valid number of characters",
                minLen: errorMessages?.minLen ? errorMessages.minLen : "Please enter valid number of characters",
                maxLen: errorMessages?.maxLen ? errorMessages.maxLen : "Please enter valid number of characters",
                number: errorMessages?.number ? errorMessages.number : "Please enter a number",
                password: errorMessages?.password ? errorMessages.password : "Password must be at least 8 characters with 1 uppercase, 1 lowercase, and 1 digit",
                passwordMatch: errorMessages?.passwordMatch ? errorMessages.passwordMatch : "Passwords do not match",
                reCaptcha: errorMessages?.reCaptcha ? errorMessages.reCaptcha : "Please validate the ReCaptcha to submit the form.",
            },
        },
        settings || {}
    );
    return {
        errorMessages: { ...config.errorMessages },
        cssClass: {
            error: "error-message",
        },
        uuid: config.uuid,
        step: config.step,
        stepName: "",
        totalSteps: 1,
        isMdmEnabled: config.isMdmEnabled,
        siteOverride: config.siteOverride,
        contextPath: config.contextPath,
        formType: config.formType,
        formName: config.formName,
        inputElements: [],
        firstInputId: "",
        leaveFormUrl: "",
        P13N_COOKIE: "snfi_pref",
        REG_COOKIE: "snfi_reg",
        hasMdmStep: config.hasMdmStep,
        hasLeaveFormModal: config.hasLeaveFormModal,
        hasCaptchaError: false,
        isCheckingMedicalId: false,
        isMdmVerificationSuccess: false,
        isMdmVerificationFailed: false,
        init() {
            this.inputElements = [...this.$el.querySelectorAll("input[data-rules]"), ...this.$el.querySelectorAll("select[data-rules]")];
            this.initDomData();
            this.pien();
            this.rien();
            this.showErrorMessages(this.errorMessages);
            this.setEmailValidator();
            showPassword();

            if (this.formType == "multiStep") {
                this.totalSteps = this.$el.querySelectorAll("fieldset").length;
                this.getStepInputElements();
                this.leaveFormModalInit();
            }

            if (this.hasMdmStep) {
                this.step = 2;
                this.totalSteps = 2;
                this.stepName = "My Info";
            }
        },
        // For Login, Forgot Password & Reset Password Form
        formInit() {
            let form = this.$el.querySelector("form");
            if (form) {
                let _this = this;
                this.setInputEvent(form);

                form.addEventListener("submit", function () {
                    let inputErrors = [];
                    let inputs = form.querySelectorAll(".capture_form_item input");
                    inputs.forEach(function (input) {
                        let type = input.getAttribute("type");
                        if ((type == "email" && !validator.isEmail(input.value)) || (type == "password" && _this.isInvalidPassword(form, input))) {
                            inputErrors.push(input.dataset.capturefield);
                        }
                    });

                    _this.formSubmit(form, inputErrors);
                });
            }
        },
        isInvalidPassword(form, input) {
            let isInvalid = false;
            switch (input.dataset.capturefield) {
                case "newPassword":
                    let confirmPass = form.querySelector("input[name='newPasswordConfirm']");
                    isInvalid = validator.isEmpty(input.value) || input.value.length < 8 || input.value != confirmPass.value;
                    break;
                case "newPasswordConfirm":
                    let pass = form.querySelector("input[name='newPassword']");
                    isInvalid = validator.isEmpty(input.value) || input.value != pass.value;
                    break;
                default:
                    isInvalid = validator.isEmpty(input.value);
            }
            return isInvalid;
        },
        setInputEvent(form) {
            let inputs = form.querySelectorAll(".capture_form_item input");
            let _this = this;
            inputs.forEach(function (input) {
                let evt = _this.getInputEvent(input);
                input.addEventListener(evt, function () {
                    if (!window._hasUserStarted) {
                        trackEvent({
                            event: "form_start",
                            interaction_type: "form",
                            name: _this.formName,
                        });
                        window._hasUserStarted = true;
                    }
                });
            });
        },
        formSubmit(form, inputErrors) {
            let formName = this.formName;
            let errorMessages = document.querySelectorAll(".capture_tip_error:not(:empty)");
            if (inputErrors.length > 0 && errorMessages.length > 0) {
                let errors = [];
                errorMessages.forEach(function (errorMessage) {
                    if (inputErrors.find((inputError) => inputError == errorMessage.dataset.elementname)) {
                        errors.push(errorMessage.innerText);
                    }
                });

                trackEvent({
                    event: "form_error",
                    interaction_type: "form",
                    name: formName,
                    error_type: errors.join(" | "),
                });
            } else {
                let trackPostSubmitErrors = function () {
                    let submitBtn = form.querySelector("button[type='submit']");

                    if (submitBtn) {
                        let watchSubmit = window.setInterval(function () {
                            if (window.getComputedStyle(submitBtn).display != "none") {
                                window.clearInterval(watchSubmit);
                                let errorMessage = document.querySelector(".capture_form_error");
                                let fatalErrorMessage = document.querySelector(".capture_fatal_error");

                                if (errorMessage) {
                                    trackEvent({
                                        event: "form_error",
                                        interaction_type: "form",
                                        name: formName,
                                        error_type: errorMessage.innerText,
                                    });
                                }
                                if (fatalErrorMessage) {
                                    trackEvent({
                                        event: "form_error",
                                        interaction_type: "form",
                                        name: formName,
                                        error_type: fatalErrorMessage.innerText,
                                    });
                                }
                            }
                        }, 100);
                    }
                };

                if (window._hasUserFinished) {
                    trackPostSubmitErrors();
                } else {
                    // Submit Form
                    trackEvent({
                        event: "form_submit",
                        interaction_type: "form",
                        name: formName,
                    });
                    // Track post submit errors
                    trackPostSubmitErrors();
                    window._hasUserFinished = true;
                }
            }
        },
        getInputEvent(input) {
            let evt = "change";
            if (input) {
                let type = input.getAttribute("type");
                switch (type) {
                    case "radio":
                    case "checkbox":
                        evt = "click";
                        break;
                    default:
                        evt = "keyup";
                }
            }
            return evt;
        },
        isFinishedFillingForm(inputs) {
            let isFinished = true;
            inputs.forEach(function (input) {
                if (input.value == "") {
                    isFinished = false;
                }
            });
            return isFinished;
        },
        isEditProfileForm() {
            return "profile" === this.formName;
        },
        /**
         * pien() Iterates over the values in the personalization cookie, looks for checkboxes w/ matching uuid
         * value and marks the checkbox as checked if found.
         */
        pien() {
            const cookieVal = Cookies.get(this.P13N_COOKIE);
            if (!this.isEditProfileForm() && cookieVal) {
                const prefs = JSON.parse(cookieVal).categories;
                for (let i = 0; i < prefs.length; i++) {
                    const pref = prefs[i];
                    let targ = `input[data-subid="${pref}"]`;
                    let found = this.$el.querySelector(targ);
                    if (found) {
                        found.checked = true;
                    }
                }
            }
        },
        rien() {
            const cookieVal = Cookies.get(this.REG_COOKIE);
            if (cookieVal) {
                const regs = JSON.parse(cookieVal).partialValues;
                for (var key in regs) {
                    let targ = `input[data-schema="${key}"]`;
                    let found = this.$el.querySelector(targ);
                    if (found) {
                        found.value = regs[key];
                    }
                }
            }
        },
        initDomData() {
            //Create an object attached to the component state for each input element to store its state
            this.checkTermsAndConditions();
            this.inputElements.map((ele, index) => {
                if (index === 0) {
                    this.firstInputId = ele.getAttribute("id");
                }
                this[ele.dataset.schema] = {
                    focus: false,
                    blurred: false,
                };

                if (this.isUncheckedCheckbox(ele)) {
                    ele.setAttribute("value", "false");
                    this.addHiddenInput(ele);
                }
            });
        },
        showErrorMessages(errors) {
            //show akamai error messages where they exist on the form
            for (const [key, value] of Object.entries(errors)) {
                let errorIdentifier = document.querySelector("[id='" + key + "']"); // to fix "not a valid selector" issue when the id starts with a number
                if (errorIdentifier) {
                    let errorWrapper = errorIdentifier.nextElementSibling;
                    let errorEntry = document.createElement("p");
                    errorEntry.classList.add("error-message");
                    errorEntry.innerHTML = value;
                    errorWrapper.append(errorEntry);
                }
            }
        },
        makePhoneRequired() {
            if (this.formType == "multiStep") {
                this.getStepInputElements();
            }
            const elementsWithMakePhoneRequired = [...this.$root.querySelectorAll('[makephonerequired="true"]')];
            const hasRequiredCheckboxes = elementsWithMakePhoneRequired.filter(checkbox => checkbox.checked).length > 0;

            this.inputElements.forEach((input) => {
                if (input.dataset.schema === "mobile" || input.dataset.schema === "phone") {
                    this.makeInputRequired(input, hasRequiredCheckboxes);
                }
            });
        },
        makeInputRequired(input, isRequired) {
            const label = document.querySelector(`label[for='${input.id}']`);
            const rules = JSON.parse(input.dataset.rules, (k, v) => {
                if (v instanceof Array) {
                    if (v.includes("required") && !isRequired) {
                        v.splice(v.indexOf("required"), 1);
                        label.innerHTML = label.innerHTML.replaceAll("*", "");
                    } else if (!label.innerHTML.includes('*')) {
                        v.push("required");
                        label.innerHTML = label.innerHTML + " *";
                    }
                }
                return v;
            });
            input.setAttribute("data-rules", JSON.stringify(rules));
        },
        isCaptchaValid() {
            const response = window.grecaptcha? window.grecaptcha.getResponse(): "empty";
                if (response == "empty" || response.length > 0){
                    const isCaptchaValid = true;
                    this.hasCaptchaError = !isCaptchaValid;
                    return isCaptchaValid;
                } else {
                    const isCaptchaValid = false;
                    this.hasCaptchaError = !isCaptchaValid;
                    return isCaptchaValid;
                }
            },
        updateErrorMessages(input) {
            const schema = input.dataset.schema;
            const errorMessage = this.getErrorMessage(input);
            if (!this[schema].errorMessageByEl) {
                this[schema].errorMessageByEl = {};
            }
            // if there are multiple inputs for one schema, gather errors by inputs
            this[schema].errorMessageByEl[input.id] = errorMessage;
            // Layout the first error if there are multiple for one schema
            const firstErrorInSchema = Object.values(this[schema].errorMessageByEl).find(Boolean);
            this[schema].errorMessage = firstErrorInSchema;
        },
        getValidatorType(ele) {
            const fieldType = ele.getAttribute("type") || ele.tagName.toLowerCase();
            const typesOfText = ["text", "email", "password", "phone", "date"];
            return typesOfText.includes(fieldType) ? "string" : fieldType;
        },
        isUncheckedCheckbox(ele) {
            return ele.type === "checkbox" && !ele.checked && ele.hasAttribute("submitFalse");
        },
        addHiddenInput(ele) {
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", ele.id);
            hiddenField.setAttribute("value", "false");
            hiddenField.classList.add(`hiddenClass_${ele.id}`);
            ele.parentElement.append(hiddenField);
        },

        getErrorMessage(ele, isValidReturn) {
            const rules = JSON.parse(ele.dataset.rules);
            const validatorType = this.getValidatorType(ele);
            let errorMessage = "";
            const customRequired = ele.hasAttribute("custom-required-message") ? ele.getAttribute("custom-required-message") : null;
            const notValidRule = rules.find(rule => {
                const validateRule = validators[validatorType][rule];
                return validateRule && !validateRule(ele);
            });

            if (notValidRule && this[ele.dataset.schema].focus) {
                const minVal = ele.dataset.min;
                const maxVal = ele.dataset.max;
                switch (notValidRule) {
                    case "minMaxLen":
                    case "minLen":
                    case "maxLen":
                        errorMessage = this.errorMessages[notValidRule].replace("{{min}}", minVal).replace("{{max}}", maxVal);
                        break;
                    case "required":
                        errorMessage = Boolean(customRequired) ? customRequired : this.errorMessages[notValidRule];
                        break;
                    default:
                        errorMessage = this.errorMessages[notValidRule] || this.errorMessages["default"];
                        break;
               }
            }

            return isValidReturn ? !notValidRule : errorMessage;
        },

        submit(event) {
            this.isMdmVerificationSuccess = false;
            this.isMdmVerificationFailed = false;

            // Disabled submit when current step is not on the last step
            if (this.formType == "multiStep" && this.step < this.totalSteps) {
                event.preventDefault();
                this.goToNextStep();
                return; // stop validating the whole form
            } else if (this.formType == "multiStep") {
                this.getStepInputElements();
            }

            if (!event.submitter.dataset.noscroll && event.submitter.dataset.noscroll != "true") {
                const isElInvalid = (input) => !this.getErrorMessage(input, true);
                const invalidElements = this.inputElements.filter((input) => isElInvalid(input));
                const formIsInvalid = invalidElements.length > 0;

                if (formIsInvalid || !this.isCaptchaValid()) {
                    event.preventDefault();
                    this.onInvalidSubmit(invalidElements);
                } else {
                    trackEvent({
                        event: "form_submit",
                        interaction_type: "form",
                        name: this.formName,
                    });

                    if (this.formType == "multiStep" || this.hasMdmStep) {
                        // Push Form Step DataLayer
                        this.pushFormStepDataLayer();
                    }

                    if (this.formName == "register") {
                        trackEvent({
                            event: "sign_up",
                            interaction_type: "authentication",
                        });
                    }
                }
            } else {
                event.preventDefault();
            }
        },
        onInvalidSubmit(invalidElements) {
            if (invalidElements) {
                const topPos = invalidElements[0].getBoundingClientRect().top + (window.pageYOffset - 150);
                window.scrollTo({
                    top: topPos,
                    behavior: "smooth",
                });
            }

            //We set all the inputs as blurred if the form has been submitted
            this.inputElements.forEach((input) => {
                this[input.dataset.schema].focus = true;
                //And update the error messages.
                this.updateErrorMessages(input);
            });

            let _this = this;
            let errors = [];
            invalidElements.forEach(function (invalidElement) {
                errors.push(`${invalidElement.dataset.schema} - ${_this.getErrorMessage(invalidElement)}`);
            });

            trackEvent({
                event: "form_error",
                interaction_type: "form",
                name: this.formName,
                error_type: errors.join(" | "),
            });
        },
        change(event) {
            let targetInput = this[event.target.dataset.schema];
            //Ignore all events that aren't coming from the inputs we're watching
            if (!targetInput) {
                return false;
            }
            if (event.type === "focusout") {
                targetInput.blurred = true;
            }
            if (event.type === "input") {
                targetInput.focus = true;

                if (JSON.parse(event.target.getAttribute("makePhoneRequired"))) {
                    this.makePhoneRequired();
                }

                if (this.isUncheckedCheckbox(event.target)) {
                    event.target.setAttribute("value", "false");
                    this.addHiddenInput(event.target);
                } else {
                    event.target.setAttribute("value", "on");
                    let hiddenInput = document.querySelector(`.hiddenClass_${event.target.id}`);
                    if (hiddenInput) {
                        hiddenInput.remove();
                    }
                }

                let identifier = event.target.dataset.identifier;
                if (identifier) {
                    let value = event.target.checked;
                    let targ = `input[pluralIdentifier="${identifier}"]`;
                    document.querySelectorAll(targ).forEach((element) => {
                        element.setAttribute("value", value);
                    });
                }

                if (!window._hasUserStarted && !this.hasMdmStep) {
                    trackEvent({
                        event: "form_start",
                        interaction_type: "form",
                        name: this.formName,
                    });

                    window._hasUserStarted = true;
                }
            }

            //Whether input or focus, show the error messages
            this.inputElements.forEach((element) => {
              
                this.updateErrorMessages(element);
                
            });
        },
        userSubscribed() {
            if (!localStorage.getItem("subscribed")) {
                localStorage["subscribed"] = true;
            }
        },
        initEventDetails() {
            if (localStorage.getItem("subscribed")) {
                localStorage.removeItem("subscribed");
                window.location.reload();
            }
        },
        setEmailValidator() {
            const inputEmailValidator = this.$el.querySelector("input[data-email-validator]");
            if (inputEmailValidator) {
                this.registerEmailValidorDisableInputs({
                    emailInput: inputEmailValidator,
                    form: inputEmailValidator.form,
                    status: true,
                });
            }
        },
        registerEmailStatus: "",
        registerEmailValidorDisableInputs({ emailInput, form, status }) {
            const formInputs = form.querySelectorAll("input, select, textarea, button");
            const isNotEmailField = (input) => input.name != emailInput.name;
            const isNotHidden = (input) => input.type !== "hidden";
            const isDisabled = (input) => !input.dataset.disabled;
            const isNoSuiFormDisable = (input) => !input.dataset.noSuiformDisable;

            const setDisabledStatus = ({ input, status }) => {
                return status ? input.setAttribute("disabled", true) : input.removeAttribute("disabled");
            };

            formInputs.forEach((input) => {
                if (isNotEmailField(input) && isNotHidden(input) && isDisabled(input) && isNoSuiFormDisable(input)) {
                    setDisabledStatus({ input, status });
                }
            });
        },
        registerEmailApiController: null,
        registerEmailValidator() {
            const self = this;
            const emailInput = this.$refs.email;
            const emailValue = this.$domPurify(emailInput.value);
            const site = emailInput.getAttribute("data-site");
            const contextPath = emailInput.getAttribute("data-path");
            const apiURL = `${contextPath}/.rest/cpc/v1/account-status`;

            const isEmailBasicValid = validators.string.email(emailInput);

            // Mapped messages from the dialog
            // sanofi-main/magnolia-akamai-authentication-core/src/main/resources/magnolia-akamai-authentication-core/dialogs/components/akamai/inputs/emailInput.yaml

            const messages = {
                0: "loading",
                1: "valid",
                2: "validAccount",
                3: "invalidEmail",
                4: "emailNotVerified",
                6: "hcpNotVerified",
                7: "noVerification",
            };

            const showStatusMessage = (data) => {
                const status = data?.status;
                const hasStatus = Object.keys(messages).includes(status);

                if (hasStatus) {
                    self.registerEmailStatus = messages[data.status];
                } else {
                    self.registerEmailStatus = "";
                }

                this.registerEmailValidorDisableInputs({
                    emailInput,
                    form: emailInput.form,
                    status: !(status == 1),
                });
            };

            const formData = new URLSearchParams();
            formData.append("siteName", site);
            formData.append("email", emailValue);
            formData["g-recaptcha-response"] = window.grecaptcha? window.grecaptcha.getResponse(): "";

            if (!isEmailBasicValid) {
                showStatusMessage({ status: "3" });
            } else {
                showStatusMessage({ status: "0" });

                if (self.registerEmailApiController) {
                    self.registerEmailApiController.abort();
                }

                self.registerEmailApiController = new AbortController();
                fetch(apiURL, {
                    headers: new Headers({
                        "Content-Type": "application/x-www-form-urlencoded",
                    }),
                    method: "POST",
                    body: formData,
                    signal: self.registerEmailApiController.signal,
                })
                    .then((response) => response.json())
                    .then((data) => showStatusMessage(data))
                    .catch((e) => {});
            }
        },
        checkTermsAndConditions() {
            let termsAnchor = document.getElementById("terms-modal");
            let termsArea = document.getElementById("tnc-text-area");
            if (termsAnchor) {
                termsAnchor.addEventListener("click", function (e) {
                    e.preventDefault();
                    // Trigger Modal Dispatch
                    window.dispatchEvent(new CustomEvent("open-modal", { detail: { id: termsAnchor.id } }));
                });
            }
            this.userScrolledBottom(termsArea);
        },
        userScrolledBottom(element) {
            if (element) {
                element.addEventListener("scroll", () => {
                    let scrollFlag = false;
                    let isClientHeight = element.scrollHeight - element.scrollTop === element.clientHeight;
                    if (isClientHeight) {
                        scrollFlag = true;
                    }
                    if (scrollFlag) {
                        let acceptButton = document.getElementById("buttonAccept");
                        if (acceptButton) {
                            acceptButton.removeAttribute("disabled");
                            acceptButton.classList.remove("pointer-events-none");
                        }
                    }
                });
            }
        },
        pushFormStepDataLayer() {
            // Update `stepName` state
            window.dispatchEvent(new CustomEvent("set-form-step-name", { detail: { step: this.step } }));

            // Push step DataLayer
            trackEvent({
                event: "form_step",
                interaction_type: "form",
                name: this.formName,
                step_name: this.stepName,
                step_number: this.step,
                length: this.totalSteps,
            });
        },
        async goToNextStep() {
            this.isMdmVerificationSuccess = false;
            this.isMdmVerificationFailed = false;

            this.getStepInputElements();
            const isElInvalid = (input) => !this.getErrorMessage(input, true);
            const invalidElements = this.inputElements.filter((input) => isElInvalid(input));
            const formIsInvalid = invalidElements.length > 0;

            if (formIsInvalid) {
                this.onInvalidSubmit(invalidElements);
            } else {
                // Push Form Step DataLayer
                this.pushFormStepDataLayer();

                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                // If MDM is enabled and professional identifier field exist on the active fieldset;
                const professionlIdentifier = this.$root.querySelector("fieldset[data-step='" + this.step + "'] input[data-schema='cs_professionalIdentifiers']");
                if (this.isMdmEnabled && professionlIdentifier) {
                    await this.verifyMedicalId(professionlIdentifier);
                }

                // Proceed to the next step
                this.step += 1;
            }
        },
        goToPrevStep() {
            this.isMdmVerificationSuccess = false;
            this.isMdmVerificationFailed = false;

            this.getStepInputElements();

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            this.step -= 1;
        },

        async verifyMedicalId(professionlIdentifier) {
            if (professionlIdentifier.value == "") {
                return false;
            }
            this.isCheckingMedicalId = true;

            const url = this.contextPath + "/.rest/verification/v2/hcp";
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    "x-sny-siteoverride": this.siteOverride,
                },
                body: this.getMdmFormData(),
            };

            const formAction = this.$root.action;
            try {
                const response = await fetch(url, options);
                let data = await response.json();
                if (data.success) {
                    // Add `.ast` in form action to enable assisted registration
                    if (!formAction.endsWith(".ast")) {
                        this.$root.action = formAction + ".ast";
                    }
                    this.isMdmVerificationSuccess = true;
                } else {
                    throw("error");
                }
            } catch (e) {
                // remove `.ast` in form action if mdm is not verified
                this.$root.dataset.action = formAction.replace(/\.ast$/, '')
                this.isMdmVerificationFailed = true;
            } finally {
                this.isCheckingMedicalId = false;
            }
        },
        getMdmFormData() {
            const formData = {};
            const inputs = this.$root.querySelectorAll(
              "fieldset[data-step='" + this.step + "'] [data-mdm-form-data]"
            );
            for (const input of inputs) {
              const mdmFormData = input.dataset?.mdmFormData;
              if (mdmFormData) {
                formData[mdmFormData] = input.value;
              }
            }
            return new URLSearchParams(formData);
        },
        getStepInputElements() {
            const stepInputElementsWrap = this.$root.querySelector("fieldset[data-step='" + this.step + "']");
            if (stepInputElementsWrap) {
                this.inputElements = [...stepInputElementsWrap.querySelectorAll("input[data-rules]"), ...stepInputElementsWrap.querySelectorAll("select[data-rules]")];
            }
        },
        leaveFormModalInit() {
            let _this = this;
            document.body.addEventListener("click", function (e) {
                const anchorTag = _this.getTargetLink(e.target);
                // Open Modal when the user click on a link after started filling up the form
                if (window._hasUserStarted && anchorTag && _this.hasLeaveFormModal) {
                    e.preventDefault();
                    _this.leaveFormUrl = _this.$domPurify(anchorTag.href);
                    _this.$dispatch("open-modal", { id: _this.uuid });
                }
            });
        },
        leaveForm() {
            if (this.$event.detail.id == this.uuid) {
                // Trigger Modal Dispatch
                this.$dispatch("close-modal");

                // Leave Form
                window.location.href = this.leaveFormUrl;
            }
        },
        getTargetLink(target) {
            let el = null;
            if (target.localName == "a" && target.href) {
                el = target;
            } else {
                if (target.closest("a") && target.closest("a").href) {
                    // when anchor link has html tag inside
                    el = target.closest("a");
                }
            }
            return el;
        },
    };
};

Alpine.data("form", dataFN);
window.sui = window.sui || {};
window.sui.form = dataFN;
