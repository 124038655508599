import { scrollTo } from "platform/src/js/modules/scrollTo";
import { breakpointIs } from "platform/src/js/modules/breakpoint";
import getDeviceType from "platform/src/js/modules/deviceCheck";
import Cookies from "js-cookie";
async function downloadFile(fetchResult) {
    var filename = fetchResult.headers.get("content-disposition").split("filename=")[1];
    var data = await fetchResult.blob();
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], { type: data.type || "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
        return;
    }
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}
function hidePopoverFilter(el) {
    const div = el.querySelector("div");
    if (div) div.remove();
}
window.Alpine.data("PDFBulkDownload", (config) => {
    const opts = {
        isModalOpen: false,
        selectAll: false,
        zip: true,
        activeFilterTab: 1,
        activeCharacter: "",
        loading: false,
        cookiecCsrf: Cookies.get("csrf"),
        cookiecJSESSIONID: Cookies.get("JSESSIONID"),
        classNameTable: "",
        ...config,
    };
    return {
        isModalOpen: opts.isModalOpen,
        selectAll: opts.selectAll,
        activeFilterTab: opts.activeFilterTab,
        activeCharacter: opts.activeCharacter,
        loading: opts.loading,
        zip: opts.zip,
        pdfIds: [],
        ids: [],
        names: [],
        fileSizePdf: 0,
        fileSizeZip: 0,
        init(classNameTable="") {
            this.isAllChecked();
            this.classNameTable=classNameTable;
            this.rotateAlphabet();
            if (breakpointIs("md")) {
                this.initContentFilterPopover();
                this.showPopover();
            }
        },
        getFiles(contextPath = "") {
            this.loading = true;
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/zip");
            myHeaders.append("Cookie", `csrf=${opts.cookiecCsrf}; JSESSIONID=${opts.cookiecJSESSIONID}`);
            myHeaders.append("Content-Type", "application/json");
            const ids = JSON.parse(JSON.stringify(this.ids));
            const zip = this.zip;
            const raw = JSON.stringify({
                fileName: `download.${zip ? "zip" : "pdf"}`,
                ids,
                zip,
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
            };
            const path = contextPath == "/" ? "" : contextPath;
            fetch(`${path}/.rest/emr/v1/pdf/multiple`, requestOptions).then((res) => {
                downloadFile(res);
                this.isModalOpen = false;
                this.loading = false;
            });
        },
        toggleAllCheckboxes() {
            this.selectAll = !this.selectAll;
            const productListTable = document.querySelector(".productListTable");
            const checkboxes = productListTable.querySelectorAll("[type=checkbox]");
            [...checkboxes].map((node) => {
                node.checked = this.selectAll;
            });
        },
        isAllChecked() {
            const productListTable = document.querySelector(".productListTable");
            if (!productListTable) return;
            const checkboxes = productListTable.querySelectorAll("[type=checkbox]");
            const selectCheckBoxAll = document.querySelector(".select-checkbox-all");
            checkboxes.forEach((node) => {
                node.addEventListener("click", () => {
                    const checkboxChecked = productListTable.querySelectorAll("[type=checkbox]:checked");
                    if (checkboxChecked.length === checkboxes.length) {
                        this.selectAll = true;
                        selectCheckBoxAll.checked = true;
                    } else {
                        this.selectAll = false;
                        selectCheckBoxAll.checked = false;
                    }
                });
            });
        },
        openModal() {
            const productListTable = document.querySelector(".productListTable");
            const checkboxChecked = productListTable.querySelectorAll("[type=checkbox]:checked");
            if (checkboxChecked.length) {
                this.isModalOpen = true;
                this.ids = [];
                this.names = [];
                let fileSize = 0;
                [...checkboxChecked].map((node) => {
                    const parent = node.closest("tr");
                    const display = window.getComputedStyle(parent).getPropertyValue("display");
                    if (display === "none") return;
                    const id = [parent.querySelector("[data-id]").dataset.id];
                    const name = [parent.querySelector("td").innerText];
                    const dataSize = [parent.querySelector("[data-size]").dataset.size];
                    fileSize += parseFloat(dataSize);
                    this.ids = [...this.ids, ...id];
                    this.names = [...this.names, ...name];
                });
                this.fileSizePdf = fileSize.toFixed(2);
                this.fileSizeZip = (this.fileSizePdf - (this.fileSizePdf * 0.41634/100)).toFixed(2);
            } else {
                window.alert("1つ以上のチェックボックスにチェックを入れてください。");
            }
        },
        isDisabledCharacter(activeFilterTab) {
            const _root = this.$root;
            const _self = this.$el;
            const characterId = `character-${_self.dataset.category}`;
            const characterSelector = _root.querySelectorAll(`.productItemHeader[data-character="${characterId}"]`);
            const bool = [];
            if (characterSelector.length) {
                if (activeFilterTab === 3) return true;
                characterSelector.forEach(({ dataset }) => {
                    bool.push(Number(dataset.productOriginal) === activeFilterTab);
                });
                return bool.includes(true);
            }
            return false;
        },
        setActiveTab(activeFilterTab) {
            this.activeFilterTab = activeFilterTab;
        },
        setActiveCharacter(activeCharacter) {
            this.activeCharacter = activeCharacter;
        },
        scrollTo(id) {
            if (getDeviceType.getDeviceType() === 'tablet') return;
            const characterId = document.querySelector(`[data-character="${id}"]`);
            if (!characterId) return;
            const itemId = characterId.id;
            const contentTableItem = document.querySelector('[data-table-content-item]');
            if (contentTableItem) {
                const parentEl = contentTableItem.parentElement;
                const elementTopPos = characterId.offsetTop;
                if (elementTopPos > parentEl.clientHeight) {
                    parentEl.scrollTop = elementTopPos;
                } else {
                    parentEl.scrollTop = 0;
                }
            }
            scrollTo(itemId);
        },
        rotateAlphabet() {
            const productTitle = document.querySelector(".productTitleTable");
            if (productTitle) {
                const allVertcalText = productTitle.querySelectorAll(".vertical");
                if (!allVertcalText.length) return;
                [...allVertcalText].map((node) => {
                    let html = node.innerText;
                    const character = html.split('');
                    for (let i = 0; i < character.length; i++) {
                        if (character[i].match(/[a-zA-Z0-9]/)) {
                            html = html.replaceAll(character[i], `<span class="eigo">${character[i]}</span>`);
                        }
                    }
                    node.innerHTML = html;
                });
            }
        },
        initContentFilterPopover() {
            const categories = document.querySelectorAll(".product_list [data-category]");
            if (!categories.length) return;
            [...categories].map((cate) => {
                const characters = document.querySelectorAll(`[data-table-content-item] [data-character="character-${cate.dataset.category}"]`);
                if (characters.length) {
                    let html = document.createElement("div");
                    [...characters].map((char) => {
                        const characterEl = document.getElementById(char.id);
                        const link = characterEl.querySelector("a");
                        const name = link ? link.innerText||link.textContent : "";
                        html.innerHTML += `<a href="#${char.id}">${name}</a>`;
                    });
                    return cate.setAttribute("data-content", html.innerHTML);
                }
            });
        },
        showPopover() {
            const _root = this.$root;
            const categories = document.querySelectorAll(".product_list [data-category]");
            if (!categories.length) return;
            categories.forEach((node) => {
                node.addEventListener("mouseover", () => {
                    const div = document.createElement("div");
                    div.classList.add("product-filter__popover")
                    div.innerHTML = node.dataset.content;
                    const divEl = node.querySelector("div");
                    if (!divEl) {
                        node.appendChild(div);
                        const links = div.querySelectorAll("a");
                        if (!links.length) return;
                        links.forEach((link) => {
                            const id = link.hash.replace("#", "");
                            const productheader = _root.querySelector(`.productItemHeader[id="${id}"]`);
                            const productOriginal = Number(productheader.dataset.productOriginal);
                            if (productOriginal !== this.activeFilterTab && this.activeFilterTab !== 3) link.remove();
                            link.addEventListener("click", (e) => {
                                e.preventDefault();
                                const id = link.getAttribute("href");
                                if (!id) return;
                                const contentTableItem = document.querySelector("[data-table-content-item]");
                                if (contentTableItem) {
                                    const parentEl = contentTableItem.parentElement;
                                    const characterId = document.getElementById(id.replace("#", ""));
                                    const elementTopPos = characterId.offsetTop;
                                    if (elementTopPos > parentEl.clientHeight) {
                                        parentEl.scrollTop = elementTopPos;
                                    } else {
                                        parentEl.scrollTop = 0;
                                    }
                                }
                                scrollTo(id);
                                hidePopoverFilter(node);
                            });
                        });
                    }
                });

                node.addEventListener("mouseleave", () => {
                    hidePopoverFilter(node);
                });
            });
        },
    };
});
