/* eslint-disable */
let validator;
const form = document.querySelector(".jsValidate");

if (form) {
    try {
        import("validator/validator").then((module) => {
            validator = module.default;
        });
    } catch (error) {
        console.log(error.message);
    }
}

export const getEl = (id) => {
    let element = document.getElementById(id);
    if (element && element.value) {
        return element.value.toString();
    }

    return "";
};

export const DEVICE_SERIAL_TABLE = {
    mystarextra: /^(BCAB|bcab|BCBB|bcbb|BCCB|bccb)[0-3]{1}\d{1}[1-9|X-Z|x-z]{1}[89ABCDEFGHIJabcdefghij]{1}[0-9|A]{1}\d{5}/,
    mystarplus: /^(DFAD|dfad|DGAD|dgbad|DHAD|dhad)[0-3]{1}\d{1}[1-9|X-Z|x-z]{1}[89ABCDEFGHIJabcdefghij]{1}[0-9|A]{1}\d{5}/,
    bgstar: /^(JQBD|jqbd|JQCD|jqcd)[0-3]{1}\d{1}[1-9|X-Z|x-z]{1}[89ABCDEFGHIJabcdefghij]{1}[0-9|A-Z|a-z]{1}\d{5}/,
    mystardosecoach: /^(MH|mh)[0-9|A-Z]{1}[0|A-Z]{1}\d{2}[1-9|X-Z|x-z|Y-y]{1}[89ABCDEFGHIJabcdefghij]{1}[0-9|A-Z|a-z]{1}\d{5}/,
};

export const findDeviceBySerial = (serial) => {
    let device = null;
    Object.keys(DEVICE_SERIAL_TABLE).forEach((key) => {
        if (DEVICE_SERIAL_TABLE[key].test(serial)) {
            device = key;
        }
    });
    return device;
};

export const VALIDATORS = {
    required: (value) => validator.isLength(value, { min: 1, max: 50 }),
    email: (value) => validator.isEmail(value),
    emailMatch: () => validator.equals(getEl("emailAddress"), getEl("confirmEmailAddress")),
    password: (value) => validator.isLength(value, { min: 5, max: 25 }),
    passwordMatch: () => validator.equals(getEl("password"), getEl("confirmPassword")),
    serialNumber: (value) => {
        let found = findDeviceBySerial(value);
        if (found === null) {
            return false;
        } else {
            return true;
        }
    },
    month: (value) => {
        return value !== "";
    },
    year: (value) => {
        return value !== "";
    },
    zipCode: (value) => validator.isPostalCode(value, "US") || validator.isPostalCode(value, "FR"),
    address: (value) => validator.isLength(value, { min: 1, max: 38 }),
    city: (value) => validator.isLength(value, { min: 1, max: 32 }),
    phoneNumber: (value) => validator.isMobilePhone(value, ["en-US", "fr-FR"]),
    newsletter: () => true,

    checked: () => {
        let checkbox = document.getElementById("termsAndConditions");
        return checkbox.checked;
    },

    deviceType: () => true,
};
