import Cookies from "js-cookie";
import {pushEvent} from "platform/src/js/modules/track";
import {getUUID} from "../../helpers/getUser";

window.Alpine.data("emrCalendarEvents", () => {
    return {
        today: new Date(),
        month: "",
        year: "",
        noOfDays: [],
        displayMode: "calendar",
        eventToShow: "",
        selectedEvent: null,
        eventsByMonth: [],
        eventsByDate: {},
        events: [],
        eventsToFilter: "all",
        categoriesToFilter: ["all"],
        categoriesToFilterLabels: {},
        categoryElements: ["categoriesToFilter", "categoriesToFilterListView"],
        loading: false,
        calendarFolder: "",
        contextPath: "",
        watchTheWebinarLabel: "",
        registerNowLabel: "",
        registrationClosedLabel: "",
        registeredLabel: "",
        watchTheRecapLabel: "",
        monthsPerSkip: 3,
        monthOffset: 0,
        professionTypeFilter: "",
        filteredEvents: [],
        filters: [],

        initCalendar(registeredText, monthsPerSkip, contextPath, calendarFolder, watchTheWebinarLabel, registerNowLabel, registrationClosedLabel, watchTheRecapLabel, defaultProfessionTypeFilter) {
            this.monthsPerSkip = parseInt(monthsPerSkip);
            this.contextPath = contextPath;
            this.month = this.today.getMonth();
            this.year = this.today.getFullYear();
            this.calendarFolder = calendarFolder;
            this.watchTheWebinarLabel = watchTheWebinarLabel;
            this.registerNowLabel = registerNowLabel;
            this.registrationClosedLabel = registrationClosedLabel;
            this.watchTheRecapLabel = watchTheRecapLabel;
            this.registeredLabel = registeredText;
            this.professionTypeFilter = defaultProfessionTypeFilter;
            this.fetchEvents();
            this.getNoOfDays();
        },
        fetchEvents() {
            const self = this;
            this.events = [];
            this.eventsByDate = [];
            this.eventsByMonth = [];
            this.loading = true;

            const queryParams = new URLSearchParams();

            if (this.month || this.month === 0) { // 0 needs to be explicitly validated
                queryParams.append("month", this.month);
            }

            if (this.year) {
                queryParams.append("year", this.year);
            }

            if (this.calendarFolder) {
                queryParams.append("calendarNode", this.calendarFolder);
            }

            if (this.professionTypeFilter) {
                queryParams.append("professionTypeFilter", this.professionTypeFilter);
            }

            fetch(`${this.contextPath}/.rest/emr/webinars/v1/event?${queryParams.toString()}`, {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    return res.json();
                })
                .then((json) => {
                    if (Array.isArray(json.events)) {
                        json.events.forEach((item) => {
                            const date = new Date(item.startDate);
                            const currEvent = this.transformEvent(item);
                            if (Array.isArray(item?.relatedSeminarList)) {
                                currEvent["related_event"] = item?.relatedSeminarList.map((relatedEvent) => this.transformEvent(relatedEvent));
                            }
                            self.events.push(currEvent);
                            if (date.getMonth() === this.month && date.getFullYear() === this.year) {
                                self.eventsByMonth.push(currEvent);
                            }
                        });
                    }
                    const eventsByDate = self.eventsByMonth.reduce((eventsByDate, event) => {
                        const date = event.start_date_string;
                        if (!eventsByDate[date]) {
                            eventsByDate[date] = [];
                        }
                        eventsByDate[date].push(event);
                        return eventsByDate;
                    }, {});

                    Object.entries(eventsByDate).forEach((e) => this.sortByDateAndTitle(e[1]));
                    self.eventsByDate = {...eventsByDate};
                    self.loading = false;
                });
        },

        transformEvent(event) {
            const releaseDate = this.convertToJST(new Date(event.date));
            const startDate = this.convertToJST(new Date(event.startDate));
            const endDate = this.convertToJST(new Date(event.endDate));

            let checkForCookie = Cookies.get(`registeredEvent_${event.id}`);
            let canRegister = this.canRegisterByDaysOut(startDate, 2);

            let truncatedTitle = event.seminarName.substring(0, 25);
            if (truncatedTitle.length < event.seminarName.length) {
                truncatedTitle = `${truncatedTitle}`;
            }

            let detailLabel = this.watchTheWebinarLabel;
            if (!canRegister) {
                // Has event been registered to already or has the registration period ended?
                detailLabel = checkForCookie ? this.registeredLabel : this.registrationClosedLabel;
                //It even is in the future?
            } else if (this.today.getTime() < releaseDate.getTime()) {
                // Check if there is a cookie for reserved if so return Already Registered Label
                detailLabel = checkForCookie ? this.registeredLabel : this.registerNowLabel;
                // Event is happening now
            } else if (this.today.getTime() >= startDate.getTime() && this.today.getTime() <= endDate.getTime()) {
                detailLabel = this.watchTheWebinarLabel;
                // Date is in the past?
            } else if (this.today.getTime() > endDate.getTime()) {
                detailLabel = this.watchTheRecapLabel;
            }

            let isNormalTooltip = false;
            let isSpecialTooltip = false;
            let isLastWeekTooltip = false;

            if ((releaseDate.getDay() + releaseDate.getDate()) % 4 === 0) {
                isLastWeekTooltip = true;
            }
            if (releaseDate.getDay() === 5 || releaseDate.getDay() === 6) {
                isSpecialTooltip = true;
            }
            if (releaseDate.getDay() !== 5 && releaseDate.getDay() !== 6) {
                isNormalTooltip = true;
            }

            return {
                event_id: event?.id,
                event_time: releaseDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }).toLowerCase(),
                release_date: releaseDate,
                isReservedViaCookie: checkForCookie ? true : false,
                start_date: startDate,
                start_date_string: startDate.toLocaleString("default", { month: "2-digit", day: "2-digit" }),
                start_time: startDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: false }).toLowerCase(),
                is_normal_tooltip: isNormalTooltip,
                is_special_tooltip: isSpecialTooltip,
                is_lastweek_tooltip: isLastWeekTooltip,
                end_date: endDate,
                end_time: endDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: false }).toLowerCase(),
                detail_label: detailLabel,
                release_date_string: releaseDate.toLocaleString("default", { year: "numeric", month: "long", day: "numeric" }),
                event_title: event?.seminarName,
                event_truncated_title: truncatedTitle,
                event_description: event?.description,
                event_category: event?.category.categoryName,
                event_category_display_name: event?.category.displayName,
                event_product: event?.productName,
                event_shortDescription: event?.shortDescription,
                event_speakers: Array.isArray(event.speakerList) ? event.speakerList : [],
                event_url: event?.url,
                event_backup_url: event?.backupUrl,
                event_detail_url: event?.detailPageUrl,
                event_is_within_registerable_days: canRegister,
                streaming_label: event?.streamingButtons[0].title,
                streaming_link: this.setLink(event?.streamingButtons[0].external),
            };
        },

        canRegisterByDaysOut(d1, days) {
            let diff = Math.abs(d1.getTime() - Date.now());
            diff = diff / (1000 * 60 * 60 * 24);
            return diff > days;
        },

        changeDisplayMode(displayMode = "calendar") {
            if (displayMode !== "calendar" && displayMode !== "list") {
                displayMode = "calendar";
            }
            this.displayMode = displayMode;
        },

        handleOnNextMonthBtn() {
            if (this.monthOffset + 1 <= 12) {
                if (this.month === 11) {
                    this.month = 0;
                    this.year++;
                } else {
                    this.month++;
                }
                this.monthOffset += 1;
            }
            this.getNoOfDays();
            this.fetchEvents();
        },

        handleOnPreviousMonthBtn() {
            if (this.monthOffset - 1 >= -12) {
                if (this.month === 0) {
                    this.month = 11;
                    this.year--;
                } else {
                    this.month--;
                }
                this.monthOffset -= 1;
            }
            this.getNoOfDays();
            this.fetchEvents();
        },
        handleNextSkip() {
            if (this.monthOffset + this.monthsPerSkip <= 12) {
                this.monthOffset += this.monthsPerSkip;
                let month = this.today.getMonth();
                if (this.monthOffset + month > 11) {
                    this.year = this.today.getFullYear() + 1;
                    this.month = this.monthOffset + month - 12;
                } else if (this.monthOffset + month < 0) {
                    this.year = this.today.getFullYear() - 1;
                    this.month = 11 - Math.abs(this.monthOffset + month);
                } else {
                    this.month = month + this.monthOffset;
                }
            } else {
                this.monthOffset = 12;
                this.year = this.today.getFullYear() + 1;
                this.month = this.today.getMonth();
            }

            this.fetchEvents();
            this.getNoOfDays();
        },
        handlePreviousSkip() {
            let month = this.today.getMonth();
            let preOffset = this.monthOffset - this.monthsPerSkip;
            if (preOffset >= -12) {
                this.monthOffset = preOffset;
                if (this.monthOffset + month < 0) {
                    this.year = this.today.getFullYear() - 1;
                    this.month = 12 - Math.abs(this.monthOffset + month);
                } else {
                    this.year = this.today.getFullYear();
                    this.month = this.monthOffset + month;
                }
            } else {
                this.monthOffset = -12;
                this.year = this.today.getFullYear() - 1;
                this.month = month;
            }

            this.fetchEvents();
            this.getNoOfDays();
        },

        handleBackToToday() {
            this.monthOffset = 0;
            this.year = this.today.getFullYear();
            this.month = this.today.getMonth();
            this.fetchEvents();
            this.getNoOfDays();
        },

        detailEvent(selectedEventId) {
            this.selectedEvent = this.events.find((eventItem) => eventItem.event_id === selectedEventId);
            window.location.href = this.selectedEvent?.event_detail_url;
        },

        isToday(date) {
            let d = new Date(this.year, this.month, date);

            if (d.toString() === "Invalid Date") {
                d = new Date(date);
            }

            return this.today.toDateString() === d.toDateString();
        },

        isPrevious(date) {
            let d = new Date(this.year, this.month, date);
            if (d.toString() === "Invalid Date") {
                d = new Date(date);
            }
            return this.today.toDateString() > d.toDateString();
        },
        isUpcoming(date) {
            let d = new Date(this.year, this.month, date);
            if (d.toString() === "Invalid Date") {
                d = new Date(date);
            }
            return this.today.getTime() < d.getTime();
        },

        showEventTooltip(eventId) {
            this.eventToShow = eventId;
        },

        closeEventTooltip() {
            this.eventToShow = 0;
        },

        getNoOfDays() {
            let lastDateOfCurrMonth = new Date(this.year, this.month + 1, 0);
            let lastDateOfPreviousMonth = new Date(this.year, this.month, 0);
            let daysInMonth = lastDateOfCurrMonth.getDate();
            let daysInPreviousMonth = lastDateOfPreviousMonth.getDate();
            // find where to start calendar day of week
            let dayOfWeek = new Date(this.year, this.month).getDay();
            let blankdaysArray = [];
            for (let i = dayOfWeek - 1; i >= 0; i--) {
                blankdaysArray.push(daysInPreviousMonth - i);
            }

            let daysArray = [];
            for (let i = 1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }
            this.blankdays = [...blankdaysArray];
            this.noOfDays = [...daysArray];

            let blankdaysArray2 = [];
            if (lastDateOfCurrMonth.getDay() !== 5) {
                for (let i = 1; i <= 5 - lastDateOfCurrMonth.getDay() + 1; i++) {
                    blankdaysArray2.push(i);
                }
            }
            this.blankdays2 = [...blankdaysArray2];
        },

        viewMore(date, month, year) {
            this.changeDisplayMode("list");
            let id = `eventGroup_${date}_${month}_${year}`;
            const eventGroupSelector = document.getElementById(id);

            if (eventGroupSelector) {
                setTimeout(() => {
                    eventGroupSelector.scrollIntoView({
                        behavior: "smooth",
                    });
                }, 10);
            }
        },

        addFilterCategory(selectCategory) {
            let category = selectCategory.value;
            if (category === "all") {
                this.resetFilterCategories();
                return;
            }
            let label = selectCategory.options[selectCategory.selectedIndex].text;
            if (!this.categoriesToFilter.includes(category)) {
                this.categoriesToFilter.push(category);
                this.categoriesToFilterLabels[category] = label;
            }

            this.resetCategoryElements();
        },

        removeFilterCategory(category) {
            let index = this.categoriesToFilter.indexOf(category);
            if (index !== -1) {
                let _categories = [...this.categoriesToFilter];
                _categories.splice(index, 1);
                this.categoriesToFilter = _categories;
                delete this.categoriesToFilterLabels[category];
            }
            if (this.categoriesToFilter.length === 1) {
                this.resetCategoryElements("all");
            }
        },

        resetCategoryElements(value = "") {
            this.categoryElements.forEach((el) => {
                let categoriesSelect = document.getElementById(el);
                if (categoriesSelect) {
                    categoriesSelect.value = value;
                }
            });
        },

        resetFilterCategories() {
            this.categoriesToFilter = ["all"];
            this.categoriesToFilterLabels = {};
        },

        sortByDateAndTitle(events) {
            if (events) {
                events.sort((e1, e2) => {
                    const e1Date = Date.parse(e1.start_date);
                    const e2Date = Date.parse(e2.start_date);
                    let retVal = e1Date - e2Date;
                    if (retVal === 0) {
                        retVal = e1.event_title.localeCompare(e2.event_title);
                    }
                    return retVal;
                });
            }
        },

        applyFilter(events, type = "calendar") {
            let isValidCategory = (e) => this.categoriesToFilter.includes(e.event_category) || this.categoriesToFilter.length === 1;

            let year = this.year,
                month = this.month,
                date = this.date;
            let isInCalendar = (e) => new Date(e.release_date).toDateString() === new Date(year, month, date).toDateString();

            let eventsToFilter = this.eventsToFilter;
            let isValidDate = (e) => {
                let isValid = true; // default "all"
                let eventDate = new Date(e.release_date).getTime();
                let currentDate = new Date().setHours(0, 0, 0, 0);
                switch (eventsToFilter) {
                    case "upcoming":
                        isValid = currentDate <= eventDate;
                        break;
                    case "past":
                        isValid = currentDate > eventDate;
                        break;
                    default:
                }
                return eventsToFilter === "all" || isValid;
            };
            if (events) {
                events = events.filter((e) => isValidCategory(e) && isValidDate(e) && (type === "calendar" ? isInCalendar(e) : true));
                this.sortByDateAndTitle(events);
            } else {
                events = [];
            }
            return events;
        },

        registerEventCookie(event) {
            //Update button styles
            const cursorNotAllowed = "cursor-not-allowed";
            document.querySelectorAll(`[data-registeredevent="${event.event_id}"]`).forEach((el) => {
                if (!el.classList.contains(cursorNotAllowed)) {
                    el.classList.replace("cursor-pointer", cursorNotAllowed);
                    el.querySelector(".regButton").classList.add("opacity-50", "bg-tertiary2", "border-tertiary2");
                    el.querySelector(".regButtonInner").innerHTML = this.registeredLabel;
                }
            });

            // Create Cookie
            document.cookie = `registeredEvent_${event.event_id}=予約済み_${event.event_id}; path=/; expires=${event.start_date};`;

            pushEvent({
                event: "cmp_event",
                event_type: "webcon",
                title: document.title,
                url: window.location.href,
                timestamp: new Date().getTime(),
                UUID: getUUID(),
            });
        },

        convertToJST(date) {
            // Change passed in date to JST timezone
            let newDate = date.toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
            });
            return new Date(newDate);
        },

        getStartDate(event, format) {
            let formattedResponse = "-";
            if (!event || !event[0] || !Object.hasOwn(event[0], "start_date")) {
                return formattedResponse;
            }

            const startDate = new Date(event[0].start_date);

            switch (format) {
                case "date":
                    return startDate.getDate();
                case "day":
                    return startDate.getDay();
            }
        },

        checkPreviousDate(endDate) {
            endDate = new Date(endDate).getTime();
            return Date.now() < endDate;
        },

        showStreamingBtn({event_id, start_date, end_date}) {
            let checkForCookie = Cookies.get(`registeredEvent_${event_id}`);
            return !this.canRegByDaysOut(start_date, 3, end_date) && checkForCookie;
        },

        checkEventStatus({isReservedViaCookie, event_is_within_registerable_days, end_date}) {
            return isReservedViaCookie || !event_is_within_registerable_days || !this.checkPreviousDate(end_date);
        },

        changeButtonText({end_date, detail_label}, closedLabel) {
            return this.checkPreviousDate(end_date) ? detail_label : closedLabel;
        },

        canRegByDaysOut(startDate, days, endDate) {
            startDate = new Date(startDate);
            const prevDate = this.checkPreviousDate(endDate);
            let diff = Math.abs(startDate.getTime() - Date.now());
            diff = diff / (1e3 * 60 * 60 * 24);

            return diff > days && prevDate;
        },

        setLink(url) {
            const {origin, host, hash, pathname, search} = new URL(url);
            const setparam = getUUID();
            const idoperators = {
                "hcp-portal.jp": "pid",
                "sanofimr.mcube-stream.com": "mid",
                "sanofi.3esys.jp": "mid",
                "sanofi2.3esys.jp": "mid",
                "sanofi.medicalexpert.jp": "pid",
                "seminar.vcube.com": "p",
                "ondemand.seminar.vcube.com": "p",
                "webinar.phase-one.tokyo": "p",
                "mce-portal.jp": "mid",
                "e-mr.sanofi.co.jp": "mid",
            };

            // Check if janrainCaptureProfileData.uuid has been set
            if (setparam) {
                // Check if Streaming Button URL Domain is in the idoperators object
                if (host in idoperators) {
                    // Set param to be appended to URL
                    const paramset = `?${this.setParameter(search, idoperators[host], setparam)}`;
                    let completeURL = "";

                    // Create the complete URL
                    const newURLPath = `${origin}${pathname}${paramset}`;
                    completeURL = new URL(hash, newURLPath);

                    // Set href value to new URL that includes the new parameter
                    return completeURL.href;
                }
            }

            return url;
        },

        setParameter(param, key, setparam) {
            const fetchParam = new URLSearchParams(param);
            fetchParam.set(key, setparam);
            return fetchParam.toString();
        },
    };
});
