import { breakpointIs } from "platform/src/js/modules/breakpoint";
window.Alpine.data("emrProductNav", (config) => {
    const opts = { productFilter: {category: 0, character: '', characterFilter: ''}, products: [], activeTab: '',  ...config };
    return {
        productFilter: opts.productFilter,
        activeTab: opts.activeTab,
        products: opts.products,
        isShown(productName = '', category = 0){
            if(parseInt(this.productFilter?.category) !== 0 && parseInt(category) !== parseInt(this.productFilter.category)){
                return false
            }
            if(this.productFilter?.character !== '' && this.productFilter?.characterFilter !== '' && this.productFilter.characterFilter.indexOf(productName[0]) === -1){
                return false;
            }
            return true;
        },
        setCategory(category, isMouseorverEvent = false){
            if(isMouseorverEvent && !breakpointIs("lg") && !breakpointIs("xl")){
                return;
            }
            if(parseInt(category) === parseInt(this.productFilter.category)){
                return;
            }
            this.productFilter['category'] = parseInt(category);
            const activeProducts = parseInt(category) === 0 ? [...this.products] : [...this.products.filter(product => product.category === parseInt(category))];
            this.changeCharacterStatus(activeProducts, true);
        },
        isDisabledCharacter(character){
            const characterSelector = document.querySelector(`.emrProductNav__character[data-value="${character}"]`);
            if(!characterSelector){
                return false;
            }
            return parseInt(characterSelector.getAttribute('data-disabled')) === 1;
        },
        setCharacter(character, characterFilter = '', isMouseorverEvent = false) {
            if(this.isDisabledCharacter(character)){
                return;
            }
            if((isMouseorverEvent && (breakpointIs("lg") || breakpointIs("xl"))) || (!isMouseorverEvent && character !== this.productFilter['character'])){
                this.productFilter['character'] = character;
                this.productFilter['characterFilter'] = characterFilter;
            }
        },
        changeCharacterStatus(products, isFirstTime = false){
            const characterSelectors = document.querySelectorAll('.emrProductNav__character');
            const __ = this;
            const activeCharacter = this.productFilter.character;
            let activatedCharacter = false;
            characterSelectors.forEach(function (characterSelector){
                const currCharacter = characterSelector.getAttribute('data-value');
                const currFilter = characterSelector.getAttribute('data-filter');
                const foundProduct = products.find(product => currFilter.indexOf(product.name[0]) > -1);
                if(isFirstTime && currCharacter === activeCharacter){
                    characterSelector.classList.add('bg-primary2', 'text-white');
                    activatedCharacter = true;
                }else if(foundProduct){
                    if(!activatedCharacter){
                        __.productFilter['character'] = currCharacter;
                        __.productFilter['characterFilter'] = currFilter;
                        characterSelector.classList.add('bg-primary2', 'text-white');
                        activatedCharacter = true;
                    }
                    characterSelector.setAttribute('data-disabled', 0);
                    characterSelector.classList.remove('bg-tertiary6', 'text-white');
                    characterSelector.classList.add('bg-tertiary7', 'text-primary2');
                }else{
                    characterSelector.setAttribute('data-disabled', 1);
                    characterSelector.classList.remove('bg-tertiary7', 'text-primary2');
                    characterSelector.classList.add('bg-tertiary6', 'text-white');
                }
            })
        },
        setActiveTab(activeTab){
            this.activeTab = activeTab;
        },
        initEmrProductNav(character = '', characterFilter = '', activeTab = ''){
            const products = [];
            const productSelectors = document.querySelectorAll('.emrProductNav__product');
            for(let index = 0; index < productSelectors.length; index++){
                const id = productSelectors[index].getAttribute('data-id');
                const name = productSelectors[index].getAttribute('data-name');
                const category = productSelectors[index].getAttribute("data-category") ? parseInt(productSelectors[index].getAttribute("data-category")) : 0;
                if(id && name && category && products.findIndex(product => product.id === id) === -1){
                    products.push({id, category, name});
                }
            }
            this.products = products;
            this.productFilter['character'] = character;
            this.productFilter['characterFilter'] = characterFilter;
            this.activeTab = activeTab;
            this.changeCharacterStatus(products, true);
        }
    };

});