import isEditMode from "platform/src/js/modules/isEditMode";
import Cookies from "js-cookie";
import { showLoading } from "../../showLoading";
import { hideLoading } from "../../hideLoading";

const isLocalHost = window.location.host.indexOf("localhost") > -1 ? window.location.origin : "";

const MIN_VALUE = 1;
const MAX_VALUE = 9999;

window.Alpine.data("emrCart", (config) => {
    const opts = {
        newCart: "",
        open: isEditMode,
        items: [],
        isNotification: false,
        siteOverride: "",
        destination: "",
        destinationOptions: { 'WORK': '', 'OTHER': '' },
        hasEmailReceipt: true,
        contextPath: "",
        formData: {
            orderFirstName: "",
            orderLastName: "",
            destination: "",
            sendFirstName: "",
            sendLastName: "",
            sendCompanyName: "",
            sendZipCode1: "",
            sendZipCode2: "",
            sendPrefectureName: "",
            sendAddress1: "",
            sendAddress2: "",
            sendAddress3: "",
            sendPhone: "",
            orderData: [],
        },
        ...config,
    };

    return {
        open: opts.open,
        newCart: opts.newCart,
        selectQuantity: 0,
        hasCaptchaError: "false",
        submitPressed: "false",
        items: opts.items,
        destination: opts.destination,
        isNotification: opts.isNotification,
        contextPath: opts.contextPath,
        locations: {},
        prefectures: [],
        noCaptcha: document.querySelector("[data-no-recaptcha]"),
        cart: JSON.parse(localStorage.getItem("cart") || "[]"),

        init() {
            this.fetchLocations();
        },

        fetchLocations() {
            fetch(`${this.contextPath}/.rest/emr/dcf/v1/states`, {
                headers: this.getMyHeader(),
                method: "GET",
            })
            .then((response) => response.json())
            .then((data) => {
                data.forEach((pref) => {
                    this.prefectures.push(pref.name);
                    this.locations[pref.name] = pref.cities
                        .filter(city => city.municipalityKanjiName)
                        .map(city => city.municipalityKanjiName);
                });
            });
        },

        notifToast: {
            add: "leafletAddedToast",
            update: "leafletUpdatedToast",
            remove: "leafletRemovedToast",
        },

        get cartIndex() {
            return this.cart.length;
        },
        formData: {
            checkboxes: Array.from(document.querySelectorAll("input[type='checkbox']")).map((i) => i == false),
        },

        getMyHeader() {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", `csrf=${Cookies.get("csrf")}; JSESSIONID=${Cookies.get("JSESSIONID")}`);
            if (opts.siteOverride) {
                myHeaders.append("x-sny-siteoverride", opts.siteOverride);
            }
            return myHeaders;
        },

        getQtyOptions(options) {
            const list = [];
            for (let option of options) {
                list.push({
                    value: option.value,
                    text: option.text,
                });
            }
            return list;
        },
        cartTotal() {
            let cart = JSON.parse(localStorage.getItem("cart") || "[]");
            return cart.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
        },
        addToCartPrice(id, title, quantity, image, code, $dispatch) {
            if (quantity > 0) {
                let cart = JSON.parse(localStorage.getItem("cart") || "[]");
                const cartItemIndex = cart.findIndex((item) => item.id === id);
                if (cartItemIndex >= 0) {
                    let price = this.$refs[`select-${id}`].getAttribute("data-price");
                    let min = this.$refs[`select-${id}`].getAttribute("data-min");
                    let max = this.$refs[`select-${id}`].getAttribute("data-max");
                    cart[cartItemIndex] = { ...cart[cartItemIndex], quantity, price: price, min: min, max: max };
                } else {
                    let price = 0;
                    let min = 0;
                    let max = 999;
                    let quantityOptions = [];
                    price = this.$refs[`select-${id}`].getAttribute("data-price");
                    min = this.$refs[`select-${id}`].getAttribute("data-min");
                    max = this.$refs[`select-${id}`].getAttribute("data-max");
                    quantityOptions = [];
                    cart = [...cart, { id, title, quantity, price, min, max, image, code, quantityOptions }];
                }

                localStorage.setItem("cart", JSON.stringify(cart));
                $dispatch("on-cart-update");
            } else {
                this.removeFromCart(id, $dispatch);
            }
        },
        addToCart(id, title, quantity, image, code, $dispatch) {
            let cart = JSON.parse(localStorage.getItem("cart") || "[]");
            const cartItemIndex = cart.findIndex((item) => item.id === id);
            const vcId = document.querySelector(`[data-version-code="${code}"]`);
            let min = 0,
                max = 0,
                price = 0;
            if (cartItemIndex >= 0) {
                min = parseInt(cart[cartItemIndex].min);
                max = parseInt(cart[cartItemIndex].max);
            }
            if (vcId) {
                min = parseInt(vcId.min);
                max = parseInt(vcId.max);
                price = vcId.dataset.price;
            }
            if (quantity && quantity > 0) {
                if (quantity <= max && quantity >= min) {
                    if (cartItemIndex >= 0) {
                        cart[cartItemIndex] = { ...cart[cartItemIndex], quantity };
                        this.showNotificationCart(title, "update");
                    } else {
                        const quantityOptions = this.$refs[`select-${id}`].options ? this.getQtyOptions(this.$refs[`select-${id}`].options) : [];
                        cart = [...cart, { id, title, quantity, image, code, price, min, max, quantityOptions }];
                        this.showNotificationCart(title, "add");
                    }
                    localStorage.setItem("cart", JSON.stringify(cart));
                } else {
                    this.showErrorQuantity(min, max);
                }
                $dispatch("on-cart-update");
            } else if (cartItemIndex < 0) {
                this.showErrorQuantity(min, max);
            } else {
                this.removeFromCart(id, $dispatch);
            }
        },
        removeFromCart(id, $dispatch) {
            const cart = JSON.parse(localStorage.getItem("cart") || "[]");
            const leaflet = cart.find((item) => item.id == id);
            if (leaflet) {
                this.cart = cart.filter((item) => item.id !== id);
                localStorage.setItem("cart", JSON.stringify(this.cart));
                this.showNotificationCart(leaflet.title, "remove");
                $dispatch("on-cart-update");
            }
        },
        refreshCart() {
            this.cart = JSON.parse(localStorage.getItem("cart") || "[]");
            if (this.cart.length == 0) window.location.reload();
        },
        isCartEmpty() {
            return this.cart.length < 1;
        },
        isCaptchaValid() {
            if (!this.noCaptcha) {
                let response = window.grecaptcha.getResponse();
                return response.length > 0 ? true : false;
            }
        },
        isAllCheckboxesSelected() {
            this.hasCaptchaError = this.isCaptchaValid();
            if (this.submitPressed === true) {
                return this.formData.checkboxes.every((box) => box == true);
            }
        },
        checkout(event) {
            this.submitPressed = true;
            this.hasCaptchaError = this.isCaptchaValid();

            if (!this.noCaptcha && !this.isCaptchaValid()) {
                return false;
            }

            const formData = { ...opts.formData, ...this.getFormData(event.target) };
            const cart = this.cart.map((data) => ({
                code: data.code,
                numberOfOrders: data.quantity,
            }));

            formData.orderData = cart;

            if(formData.destination == opts.destinationOptions.WORK) {
                formData.sendFirstName = formData.orderFirstName;
                formData.sendLastName = formData.orderLastName;
            }

            if (!this.noCaptcha) {
                formData["g-recaptcha-response"] = window.grecaptcha.getResponse();
            }
            const _this = this;

            const email = formData.email;
            if (formData.email) {
                delete formData.email;
            }

            const raw = JSON.stringify(formData);
            const requestOptions = {
                method: "POST",
                headers: this.getMyHeader(),
                body: raw,
            };

            const itemInfor = this.cart.map((data) => ({
                name: data.code,
                quantity: data.quantity,
                price: data.price,
            }));

            showLoading();

            fetch(`${isLocalHost}${event.target.dataset.contextpath}/.rest/emr/v1/leaflet/order`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.text();
                })
                .then((result) => {
                    const rs = JSON.parse(result);
                    if (rs.success) {
                        const addressParts = [];
                        if (formData.sendPrefectureName) addressParts.push(formData.sendPrefectureName);
                        if (formData.sendAddress1) addressParts.push(formData.sendAddress1);
                        if (formData.sendAddress2) addressParts.push(formData.sendAddress2);

                        const formDataEmail = {
                            address: addressParts.join(""),
                            city: formData.sendAddress3,
                            firstName: formData.sendFirstName,
                            lastName: formData.sendLastName,
                            postalCode: formData.sendZipCode1 + "-" + formData.sendZipCode2,
                            businessContact: formData.sendPhone,
                            businessName: formData.sendCompanyName,
                            email: email,
                            orderTotal: opts.hasEmailReceipt ? rs.receiptNo : rs.orderNo,
                            items: itemInfor,
                        };

                        const rawDataEmail = JSON.stringify(formDataEmail);
                        const requestOptionsEmail = {
                            method: "POST",
                            headers: this.getMyHeader(),
                            body: rawDataEmail,
                        };

                        fetch(`${isLocalHost}${event.target.dataset.contextpath}/.rest/bestsell/v1/order`, requestOptionsEmail)
                            .then((response) => response.text())
                            .then((resultOrder) => {
                                const rsOrder = JSON.parse(resultOrder);
                                if (rsOrder.orderReceived && rsOrder.orderReceived.success && rsOrder.orderToFulfill && rsOrder.orderToFulfill.success) {
                                    _this.cart = [];
                                    localStorage.setItem("cart", JSON.stringify([]));
                                    window.location.href = event.target.dataset.successpagelink;
                                    hideLoading();
                                } else {
                                    hideLoading();
                                    this.showError(rsOrder.errorMessage);
                                }
                            })
                            .catch(() => {
                                hideLoading();
                                this.showError();
                            });
                    } else {
                        hideLoading();
                        this.showError(rs.errorMessage);
                    }
                })
                .catch(() => {
                    hideLoading();
                    this.showError();
                });
        },
        inputChange(e) {
            const newFormData = opts.formData;
            opts.data = Object.keys(newFormData).map((key) => {
                if (key == e.target.name) {
                    newFormData[key] = e.target.value;
                }
                return newFormData;
            });
        },
        getFormData(form) {
            let data = {};
            form.querySelectorAll(".orderField").forEach(function (el) {
                data[el.getAttribute("name")] = el.value;
            });
            return data;
        },
        getSelectedQty(id) {
            const cart = JSON.parse(localStorage.getItem("cart") || "[]");
            const foundItem = cart.find((item) => item.id == id);
            return foundItem ? foundItem.quantity : "";
        },
        isSelectedQty(id, quantity) {
            const cart = JSON.parse(localStorage.getItem("cart") || "[]");
            return cart.find((item) => item.id == id && item.quantity == quantity);
        },
        async getInventory(contextPath) {
            let ids = [];
            const dataIds = document.querySelectorAll("[data-version-code]");

            if (!dataIds.length) return;
            showLoading();
            [...dataIds].map((node) => {
                const id = node.dataset.versionCode;
                if (id) {
                    ids = [...ids, { code: id }];
                }
            });

            const raw = JSON.stringify({ versionCodes: ids });
            const requestOptions = {
                method: "POST",
                headers: this.getMyHeader(),
                body: raw,
            };
            await fetch(`${isLocalHost}${contextPath}/.rest/emr/v1/leaflet/inventory`, requestOptions)
                .then(async (response) => {
                    if (response.ok) {
                        this.items = await response.json();
                    } else {
                        throw Error(response.statusText);
                    }
                })
                .catch(function () {
                    hideLoading();
                    this.showError();
                });

            hideLoading();
        },

        async getInventoryById(id) {
            const items = await JSON.parse(JSON.stringify(this.items));
            if (items?.versionCodes) {
                return items.versionCodes.find((x) => x.code === id);
            }
        },

        async getMinOrder(id) {
            const value = await this.getInventoryById(id);
            if (value?.minOrderUnit) {
                return value.minOrderUnit ? value.minOrderUnit : MIN_VALUE;
            }
        },

        async getMaxOrder(id) {
            const value = await this.getInventoryById(id);
            if (value?.maxOrder) {
                return value.maxOrder ? value.maxOrder : MAX_VALUE;
            }
        },

        showNotificationCart(title, type = "add") {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: title, toastId: this.notifToast[type], type: "info" } }));
        },

        showErrorQuantity(min, max) {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { min: min, max: max, toastId: "invalidOrderCountToast", type: "error" } }));
        },

        showError(message) {
            let detail = { toastId: "defaultToast", type: "error" };
            if (message) detail.message = message;
            window.dispatchEvent(new CustomEvent("show-toast", { detail: detail }));
        },
    };
});
