import Cookies from "js-cookie";
import { pushEvent } from "platform/src/js/modules/track";
import { getUUID } from "../../helpers/getUser";

window.Alpine.data("suiCalendarEventButtons", () => {
    return {
        today: new Date(),
        startDate: "",
        endDate: "",
        eventId: "",
        registeredText: "",
        watchTheWebinarLabel: "",
        registerNowLabel: "",
        registrationClosedLabel: "",
        watchTheRecapLabel: "",
        detailLabel: "",
        notAllowed: false,

        initButtons(startDate, eventId, endDate, registeredText, watchTheWebinarLabel, registerNowLabel, registrationClosedLabel, watchTheRecapLabel) {
            this.startDate = new Date(startDate);
            this.eventId = eventId;
            this.endDate = new Date(endDate);
            this.registeredText = registeredText;
            this.watchTheWebinarLabel = watchTheWebinarLabel;
            this.registerNowLabel = registerNowLabel;
            this.registrationClosedLabel = registrationClosedLabel;
            this.watchTheRecapLabel = watchTheRecapLabel;
            this.detailLabel = watchTheWebinarLabel;

            const _refs = this.$refs;
            const checkForCookie = Cookies.get(`registeredEvent_${eventId}`);
            const canRegister = this.canRegByDaysOut(startDate, 2, endDate);
            const registrationDisclaimer = _refs.regDisclaimer;            
            const streamButtonInner = _refs.streamButtonInner;
            const registrationButton = _refs.regButton;
            const registrationButtonInner = _refs.regButtonInner;
            let registrationButtonLabel = this.registerNowLabel;

            if (!canRegister) {
                registrationButtonLabel = this.registrationClosedLabel;
                registrationButton.classList.add("opacity-50", "bg-tertiary2", "border-tertiary2");
            } else if (canRegister && !checkForCookie) {
                registrationButtonLabel = this.registerNowLabel;
            } else {
                registrationButtonLabel = this.registeredText;
                registrationButton.classList.add("opacity-50", "bg-tertiary2", "border-tertiary2");
            }

            if (Date.now() >= this.endDate.getTime()) {
                registrationButton.classList.add("opacity-50", "bg-tertiary2", "border-tertiary2");
                registrationButtonLabel = this.registrationClosedLabel;
                registrationDisclaimer.classList.add("hidden");

                if (streamButtonInner) {
                    streamButtonInner.innerHTML = this.watchTheRecapLabel;
                }
            }

            if (registrationButtonInner) {
                registrationButtonInner.innerHTML = registrationButtonLabel;
            }

            // Set variables for use below
            const streamingButton = this.$refs.streamingBtn;
            if (streamingButton) {
                const { origin, host, hash, pathname, search } = streamingButton;
                const setparam = getUUID();
                const idoperators = {
                    "hcp-portal.jp": "pid",
                    "sanofimr.mcube-stream.com": "mid",
                    "sanofi.3esys.jp": "mid",
                    "sanofi2.3esys.jp": "mid",
                    "sanofi.medicalexpert.jp": "pid",
                    "seminar.vcube.com": "p",
                    "ondemand.seminar.vcube.com": "p",
                    "webinar.phase-one.tokyo": "p",
                    "mce-portal.jp": "mid",
                    "e-mr.sanofi.co.jp": "mid",
                };

                // Check if janrainCaptureProfileData.uuid has been set
                if (setparam) {
                    // Check if Streaming Button URL Domain is in the idoperators object
                    if (host in idoperators) {
                        // Set param to be appended to URL
                        const paramset = `?${this.setParameter(search, idoperators[host], setparam)}`;
                        let completeURL = "";
        
                        // Create the complete URL
                        const newURLPath = `${origin}${pathname}${paramset}`;     
                        completeURL = new URL(hash, newURLPath);
        
                        // Set href value to new URL that includes the new parameter
                        streamingButton.href = completeURL.href;
                    }
                }
            }
        },

        setParameter(param, key, setparam) {
            const fetchParam = new URLSearchParams(param);            
            fetchParam.set(key, setparam);
            return fetchParam.toString();
        },

        canRegByDaysOut(d1, days, endDate) {
            d1 = new Date(d1);
            const prevDate = this.checkPreviousDate(endDate);
            let diff = Math.abs(d1.getTime() - Date.now());
            diff = diff / (1e3 * 60 * 60 * 24);
            
            return diff > days && prevDate;
        },

        checkPreviousDate(eventDate) {
            eventDate = new Date(eventDate).getTime();
            return Date.now() < eventDate;
        },

        changeTextButton(currText, startDate, endDate, eventId) {
            let newText = currText;
            const checkDate = this.checkPreviousDate(endDate);
            const priorDate = this.canRegByDaysOut(startDate, 2, endDate)
            const checkForCookie = Cookies.get(`registeredEvent_${eventId}`);
            
            if (checkForCookie || this.notAllowed) {
                newText = this.registeredText;
            } else if (!checkDate || !priorDate) {
                newText = this.registrationClosedLabel;
            }

            return newText;
        },

        registerEventCookie(eventId) {
            //Update button styles
            const _el = this.$el;
            const cursorNotAllowed = "cursor-not-allowed";
            const checkForCookie = Cookies.get(`registeredEvent_${eventId}`);
            
            if (!checkForCookie && !_el.classList.contains(cursorNotAllowed)) {
                this.notAllowed = true;
                _el.classList.replace("cursor-pointer", cursorNotAllowed);

                // Create Cookie
                document.cookie = `registeredEvent_${this.eventId}=予約済み_${this.eventId}; path=/; expires=${this.startDate};`;

                pushEvent({
                    event: "cmp_event",
                    event_type: "webcon",
                    title: document.title,
                    url: window.location.href,
                    timestamp: new Date().getTime(),
                    UUID: getUUID(),
                });
            }
        },
    };
});
