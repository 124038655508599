import { tns } from "tiny-slider/src/tiny-slider";
window.Alpine.data("productGallery", (config) => {
    const opts = { numOfImages: 0, activeImageIndex: 1, productGallerySlider: null, ...config };
    return {
        numOfImages: opts.numOfImages,
        productGallerySlider: opts.productGallerySlider,
        activeImageIndex: opts.activeImageIndex,
        initProductGallery(numOfImages = 0, galleryWrapperClassName = "") {
            this.numOfImages = numOfImages;
            const productGalleryControls = document.querySelector(`.${galleryWrapperClassName}__controls`);
            this.productGallerySlider = tns({
                container: `.${galleryWrapperClassName}`,
                items: 1,
                slideBy: "page",
                autoplay: false,
                mouseDrag: true,
                lazyload: true,
                mode: "gallery",
                controlsContainer: productGalleryControls,
            });
            const __ = this;
            this.productGallerySlider.events.on("indexChanged", function (info) {
                __.activeImageIndex = info.displayIndex;
            });
        },
        goToSlide(slideIndex = 0) {
            this.productGallerySlider.goTo(slideIndex);
        },
    };
});
