let timeGreet = [
    {
        min: 6,
        max: 11,
        greet: "おはようございます。"
    },
    {
        min: 12,
        max: 17,
        greet: "こんにちは。"
    },
    {
        min: 18,
        max: 21,
        greet: "こんばんは。"
    }
];

function init() {
    dispatchMessage();
}

function getProfile() {
    let profileData = localStorage.getItem("janrainCaptureProfileData");
    if(profileData) {
        let dataObj = JSON.parse(profileData);
        let name = `${dataObj?.cs_lastName} ${dataObj?.cs_firstName}`;
        return name;
    }
    return false;
}

function getGreeting() {
    let dt = new Date();
    let hour = dt.getHours();
    let greeting = timeGreet.filter(({min, max}) => hour >= min && hour <= max)[0];
    return greeting?.greet || "遅くまでお疲れ様です。";
}

function dispatchMessage() {
    let profile = getProfile();
    let greeting = getGreeting();
    if (profile) {
        let message = `${profile}先生、${greeting}`;
        dispatchEvent(new CustomEvent("set-message", { detail: { message } }));
    }
}

export default { init };
