/* eslint-disable */
import Alpine from "alpinejs";
import { validateAccount, continueRegistration } from "./api";
import { initializeForm } from "./form";
import { findDeviceBySerial } from "./validators";

const STATUSES = {
    loading: -1,
    ready: 0,
    formErrors: 1,
    registered: 2,
    noAccountValidation: 3,
    noLoadAccountData: 4,
    noContinueRegister: 5,
};
const dataFN = (config) => {
    return {
        async init() {
            if (config.isEditMode) {
                this.status = STATUSES.ready;
                return;
            }

            let userData = await validateAccount(config);

            if (userData.error) {
                this.status = STATUSES.noLoadAccountData;
            } else {
                this.prefillForm(userData);
                this.status = STATUSES.ready;
            }
        },
        status: STATUSES.loading,
        form: initializeForm(config),

        prefillForm(data) {
            let neededResponseFields = ["dosNom", "dosPrenom", "carNumeroSerie", "dosemail", "dosemail"];
            let fieldsToPrefill = ["lastName", "firstName", "serialNumber", "emailAddress", "confirmEmailAddress"];

            for (let i = 0; i < fieldsToPrefill.length; i++) {
                let field = fieldsToPrefill[i];
                let input = document.getElementById(field);
                let value = data[neededResponseFields[i]];
                if (input) {
                    input.value = value;
                    input.setAttribute("readonly", true);
                }
                if (field === "serialNumber") {
                    let device = findDeviceBySerial(value);
                    this.form.handleDeviceSelect(device);
                }
            }
        },

        async handleSubmit(event) {
            this.form.validateAllFields();
            if (this.form.formIsValid) {
                this.form.status = 1;
                let validate = await continueRegistration({ ...config, form: this.form });
                if (!validate || validate.error) {
                    this.form.status = 0;
                    this.status = STATUSES.noContinueRegister; //could not register
                } else {
                    this.status = STATUSES.registered; //registered
                    window.location.href = `${config.contextPath}/espace-personnel`;
                }
            } else {
                this.status = STATUSES.formErrors;
                window.scrollTo("errorMessages", { behavior: "smooth" });
            }
        },
    };
};

Alpine.data("myStarContinueRegistration", dataFN);
window.sui = window.sui || {};
window.sui.myStarContinueRegistration = dataFN;
