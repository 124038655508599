window.Alpine.data("lotSearch", () => {
    return {
        isLoading: false,
        submitPressed: false,
        itangoCheck: false,
        searchLotQuery: "",
        lots: [],
        searchForLot(jsonLink) {
            this.isLoading = true;
            this.submitPressed = true;
            this.itangoCheck = false;
            fetch(`${jsonLink}`)
                .then((res) => res.json())
                .then((data) => {
                    this.isLoading = false;
                    this.lots = data.product_lots.filter((obj) => {
                        const isMatch = obj.num === this.searchLotQuery;
                        if (isMatch) {
                            if (obj.info.indexOf('イタンゴ') !== -1) {
                                this.itangoCheck = true;
                            }
                        }
                        return isMatch;
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        },
    };
});
