window.Alpine.data("dcfLookUp", (config) => {
    return {
        ...config,
        showClearBtn: false,
        dcfInputElements: [],
        formInputWrap: "",
        dcfApi: "/.rest/emr/dcf/v1/search",
        lookUpData: {},
        facilities: [],
        noResultsShow: false,
        disabledSearchFacilityBtn: true,
        disableNameSearch: true,
        disableStateSearch: true,
        disableCitySearch: true,
        errorFindingCities: false,
        searchStatus: "",
        stateSearchStatus: "",
        resultsDiv: document.getElementById("dcf-search-results"),
        facilityStateSelect: document.getElementById("facilityState"),
        mainStateSelect: document.getElementById("mainFacilityState"),
        modal: document.getElementById("dcfLookupModal"),
        locations: [],
        init() {
            this.formInputWrap = this.$el.querySelector(".dcfFormInputs");
            this.dcfInputElements = [...this.formInputWrap.querySelectorAll("input"), ...this.formInputWrap.querySelectorAll("select")];
            this.fetchLocations();
        },
        fetchLocations() {
            fetch(`${this.contextPath}/.rest/emr/dcf/v1/states`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-sny-siteoverride": "emr-jp",
                },
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    this.locations = data;
                    const newArray = [""].concat(data);
                    newArray.forEach((state) => {
                        const stateName = state.name ? state.name : "";
                        const statePrevValue = state.previousValue ? state.previousValue : "";
                        const optionStr = `<option data-prev-value = "${statePrevValue}" value="${stateName}">${stateName}</option>`

                        this.mainStateSelect.innerHTML += optionStr;
                        this.facilityStateSelect.innerHTML += optionStr;

                    });
                    const facilityStateDefaultValue = this.mainStateSelect
                        .getAttribute("value");
                    const defSelectValue = this.mainStateSelect.querySelector("option[value='" + facilityStateDefaultValue + "']");

                    if (defSelectValue && facilityStateDefaultValue) {
                        defSelectValue.setAttribute("selected", "true");
                        this.lookUpData.facilityState = facilityStateDefaultValue
                    }
                })
                .catch((e) => {
                    //TODO add error handling
                    console.error(e); // eslint-disable-line
                });
        },
        clearDcf() {
            this.lookUpData = {};
            this.disableToggleMainInputs(false);

            this.showClearBtn = false;
            this.facilities = [];
        },
        nameChange() {
            this.isSearchButtonDisabled();
        },
        stateChange() {
            const state = this.lookUpData.facilityState;
            this.isSearchButtonDisabled();
            if (!state) return;
            this.errorFindingCities = false;

            //Disable City
            this.stateSearchStatus = "loading";

            const facilityCitySelect = document.getElementById("facilityCity");
            const result = this.locations.find((element) => element.name === state);

            // Add blank option as first item in arr
            const newArray = [""].concat(result.cities);

            if (newArray) {
                // Clear any previous cities options
                facilityCitySelect.length = 0;
                newArray.forEach((city, i) => {
                    let kanjiName = city.municipalityKanjiName ? city.municipalityKanjiName : "";
                    let option = document.createElement("option");
                    if (i != 0) {
                        option.value = kanjiName;
                    }
                    option.text = kanjiName;
                    facilityCitySelect.appendChild(option);
                });
            } else {
                this.errorFindingCities = true;
            }

            this.stateSearchStatus = "";
        },

        cityChange() {
            this.isSearchButtonDisabled();
            this.facilities = [];
            this.noResultsShow = false;
        },
        dcfPostTrigger() {
            const self = this;
            this.searchStatus = "loading";
            this.isSearchButtonDisabled();

            let payload = JSON.stringify({
                name: self.modal.querySelector("#facilityName").value,
                city: self.modal.querySelector("#facilityCity").value,
                state: self.modal.querySelector("#facilityState").value,
            });

            fetch(`${this.contextPath}/.rest/emr/dcf/v1/search`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-sny-siteoverride": "emr-jp",
                },
                method: "POST",
                body: payload,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.response.matchesCount == 0 || data.response.matchesCount == undefined) {
                        self.noResultsShow = true;
                        self.facilities = [];
                        this.searchStatus = "";
                    } else {
                        self.facilities = [];
                        self.noResultsShow = false;
                        data.response.matches.forEach(function (facility) {
                            const postalCode = facility.mdmAddress[0].postalCode;

                            // Get dcfNumber
                            let hco = facility.mdmSource.find((o) => o.sourceName === "ULTMARC" || o.sourceName === "SanofiJP");

                            const facilityItem = {
                                name: facility.globalName,
                                fullName: facility.globalLegalName,
                                postal: postalCode,
                                address1: facility.mdmAddress[0].addressLine1,
                                dcfNumber: hco.sourceId,
                                stateName: facility.mdmAddress[0].stateName,
                                cityName: facility.mdmAddress[0].cityName,
                            };

                            self.facilities.push(facilityItem);

                            self.searchStatus = "";
                            self.disabledSearchFacilityBtn = false;
                        });
                    }
                })
                .catch((e) => {
                    console.error(e); // eslint-disable-line
                    this.searchStatus = "";
                    this.noResultsShow = true;
                    self.disabledSearchFacilityBtn = false;
                });
        },
        disableToggleMainInputs(toggle) {
            if (toggle) {
                this.showClearBtn = true;
            }
            this.dcfInputElements.forEach(function (el) {
                if (el.type !== "hidden") {
                    el.disabled = toggle;
                }
            });
        },
        selectedFacility(item) {
            this.lookUpData.facilityGlobalName = item.fullName;
            this.lookUpData.postal1 = item.postal.split("-")[0];
            this.lookUpData.postal2 = item.postal.split("-")[1];
            this.lookUpData.address1 = item.address1;
            this.lookUpData.dcfNumber = item.dcfNumber;
            this.lookUpData.facilityState = item.stateName;
            this.lookUpData.facilityCity = item.cityName;

            const state = this.locations.find(location => this.lookUpData.facilityState === location.name)
            if (state) {
                this.lookUpData.statePreviousValue = state.previousValue;
            }
        },
        setPrevValue(e) {
            if(e) {
                const preValueElm =  e.target.selectedOptions[0];
                this.lookUpData.statePreviousValue = (preValueElm && preValueElm.getAttribute('data-prev-value')) ?
                    preValueElm.getAttribute('data-prev-value'): "";
            }
        },
        isSearchButtonDisabled() {
            this.lookUpData.facilityName;
            if (this.searchStatus != "loading") {
                this.disabledSearchFacilityBtn = this.lookUpData.facilityName || this.lookUpData.facilityCity || this.lookUpData.facilityState ? false : true;
            } else {
                this.disabledSearchFacilityBtn = true;
            }
        },
        setFacility(status) {
            this.disableNameSearch = status;
            this.disableStateSearch = status;
            this.disableCitySearch = status;
        }
    };
});
