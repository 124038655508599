import getDeviceType from "platform/src/js/modules/deviceCheck";
const SPACING_LI = 30;

function init() {
    setSubNavHeightToggle();
}

function setSubNavHeightToggle() {
    if (getDeviceType.getDeviceType() !== 'desktop') return;
    const pageInNav = document.querySelector('.emrPageInNav');
    if (!pageInNav) return;
    const pageInNavMain = pageInNav.querySelector('.emrPageInNav__main');
    const subNavItems = pageInNav.querySelectorAll('.emrPageInNav__item');
    if (subNavItems && pageInNavMain) {
        subNavItems.forEach(function (item) {
            ["mouseover", "focusin"].forEach(function (eventType) {
                item.addEventListener(eventType, function (e) {
                    const target = e.target;
                    if (target.classList.contains('emrPageInNav__itemText') || target.closest('.emrPageInNav__itemText')) {
                        const itemParent = item.parentElement;
                        const itemHeight = item.clientHeight;
                        const lis = item.querySelectorAll('ul li');
                        const heightOfBlock = lis.length * itemHeight;
                        if (heightOfBlock > itemParent.clientHeight) {
                            pageInNavMain.style.height = `${ heightOfBlock + SPACING_LI }px`;
                        } else {
                            pageInNavMain.style.height = 'auto';
                        }
                    }
                });
            });

            ["mouseleave"].forEach(function (eventType) {
                item.addEventListener(eventType, function () {
                    pageInNavMain.style.height = "auto";
                });
            });
        });
    }
}

export default { init };
