/* eslint-disable */
import Alpine from "alpinejs";
import { initValidation } from "./validation";
import { findDeviceBySerial } from "./validators";

const dataFN = (config) => {
    return {
        status: 0, //0 - not submitted, 1 - submitted with errors, 2 - confirmed
        statusError: "",
        isSubmitting: false,
        siteOverride: config.siteOverride,
        endPoint: config.endPoint,
        form: {
            fields: {
                lastName: { value: "" },
                firstName: { value: "" },
                emailAddress: { value: "" },
                address: { value: "" },
                zipCode: { value: "" },
                city: { value: "" },
                phoneNumber: { value: "" },
                confirmEmailAddress: { value: "" },
                password: { value: "" },
                confirmPassword: { value: "" },
                serialNumber: { value: "" },
                termsAndConditions: { value: false },
                newsletter: { value: false },
                deviceType: { value: "" },
                year: { value: "" },
                month: { value: "" },
            },

            validation: initValidation(config),

            get formValues() {
                return Object.keys(this.fields).map((key) => {
                    let field = document.getElementById(key);
                    let value = "";
                    if (key === "deviceType") {
                        field = document.querySelector(".device-block--active");
                        if (!field) {
                            value = "";
                        } else {
                            value = field.id;
                        }
                    } else if (key === "newsletter") {
                        let field = document.getElementById("receive");
                        value = field.value;
                    } else {
                        value = field.value;
                    }

                    return { key, value };
                });
            },

            handleChange(event) {
                let value = event.target.value;
                let id = event.target.id;
                let field = this.fields[id];
                field.value = value;
                if (id === "serialNumber") {
                    //test the regexes to find valid device type
                    let device = findDeviceBySerial(value);
                    this.handleDeviceSelect(device);
                }
                let invalidFields = this.invalidFormFields.filter((field) => field.key === id).length;
                if (invalidFields > 0) {
                    this.setFieldInvalid(id);
                } else {
                    this.setFieldValid(id);
                }
            },

            handleCheckTC(event) {
                let checked = document.getElementById("termsAndConditions");
                let isChecked = checked.checked;
                this.fields.termsAndConditions.value = isChecked;
                this.handleChange({ target: { id: "termsAndConditions", value: true } });
            },

            handleCheckNewsletter(event) {
                let checkedYes = document.getElementById("receive");
                let isCheckedYes = checkedYes.checked;
                let sendNewsletter = isCheckedYes ? isCheckedYes : false;
                this.fields.newsletter.value = checkedYes.value = sendNewsletter;
                this.handleChange({ target: { id: "newsletter", value: sendNewsletter } });
            },

            handleDeviceSelect(id) {
                let devices = document.querySelectorAll(".device-block");
                devices.forEach((d) => d.classList.remove(`device-block--active`));
                if (!id) {
                    this.fields.deviceType.value = "";
                    return;
                }
                this.fields.deviceType.value = id;

                let deviceType = document.getElementById(id);
                deviceType.classList.add("device-block--active");
            },

            get invalidFormFields() {
                return this.formValues.map((v) => ({ key: v.key, fields: this.validation[v.key].filter((val) => val.fn() === false) })).filter((v) => v.fields.length !== 0);
            },

            get formIsValid() {
                return this.invalidFormFields.length === 0;
            },

            fieldIsValid(id) {
                return this.invalidFormFields.filter((field) => field.key === id).length === 0;
            },

            setFieldInvalid(id) {
                let element = document.getElementById(id);
                element?.classList.add("invalid-input");
                this.addErrorMessages(id);
            },

            setFieldValid(id) {
                let element = document.getElementById(id);
                element?.classList.remove("invalid-input");
                this.removeErrorMessages(id);
            },

            fieldErrorMessages(id) {
                let errorObj = this.invalidFormFields.find((i) => i.key === id);
                if (errorObj) {
                    return errorObj.fields.map((f) => f.message);
                }
                return [];
            },

            addErrorMessages(id) {
                let messages = this.fieldErrorMessages(id);
                let errorContainer = document.getElementById("errorMessages");
                messages.forEach((msg) => {
                    let p = document.createElement("p");
                    p.className = "error";
                    p.textContent = msg;
                    p.setAttribute("data-error-string", msg);
                    p.setAttribute("data-error-field", id);

                    let existing = document.querySelector(`[data-error-string="${msg}"]`);
                    if (!existing) {
                        errorContainer.appendChild(p);
                    }
                });
            },

            removeErrorMessages(id) {
                let existing = document.querySelectorAll(`[data-error-field="${id}"]`);
                let errorContainer = document.getElementById("errorMessages");
                if (existing.length > 0) {
                    for (let i = 0; i < existing.length; i++) {
                        errorContainer.removeChild(existing[i]);
                    }
                }
            },

            validateAllFields() {
                this.formValues.forEach((fv) => this.handleChange({ target: { id: fv.key, value: fv.value } }));
            },

            get buttonText() {
                if (this.isSubmitting) {
                    return config.button.loading;
                } else {
                    return config.button.text;
                }
            },
        },

        async handleSubmit(event) {
            this.form.validateAllFields();
            if (this.form.formIsValid) {
                this.status = 0;
                this.isSubmitting = true;
                let validate = await this.verifyAccount();
                if (validate.error) {
                    this.status = 1;
                    this.statusError = validate.error;
                    this.isSubmitting = false;
                } else {
                    this.status = 2;
                    document.querySelector("#pre-verify").scrollIntoView({ behavior: "smooth" });
                }
            } else {
                let validate = await this.verifyAccount();
                this.status = 1;
                this.isSubmitting = false;
                this.statusError = validate.error;
            }
        },

        async verifyAccount() {
            let formData = new URLSearchParams();
            let needed = ["lastName", "firstName", "emailAddress", "address", "zipCode", "city", "phoneNumber", "password", "serialNumber", "deviceType", "termsAndConditions", "newsletter", "year", "month"];

            let values = this.form.formValues.filter((fv) => needed.includes(fv.key));
            values.forEach((v) => formData.append(`${v.key}`, v.value));

            const settings = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-sny-siteoverride": this.siteOverride,
                },
                body: formData,
            };
            try {
                let request = await fetch(`${config.endPoint}`, settings);
                let response = await request.json();
                return response;
            } catch (error) {
                return { error };
            }
        },
    };
};

Alpine.data("myStarSettings", dataFN);
window.sui = window.sui || {};
window.sui.myStarSettings = dataFN;
