import { fbButton, tw, pinterest, whatsapp, linkedin, email } from "vanilla-sharing";

function init() {
    const shareButtons = document.querySelectorAll(".sharePage__button");

    shareButtons.forEach((button) => {
        button.addEventListener("click", () => onSelect(button));
        button.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                onSelect(button);
            }
        });
    });
}

function onSelect(button) {
    const icon = button.getAttribute("data-icon");
    const metaTitle = document.querySelector("meta[property='og:title']");
    const title = metaTitle ? metaTitle.content : "";
    const url = window.location.href;

    switch (icon) {
        case "facebook": {
            fbButton({ url, title });
            break;
        }
        case "twitter": {
            tw({ url, title });
            break;
        }
        case "pinterest": {
            const metaImage = document.querySelector("meta[property='og:image:secure_url']");
            const media = metaImage ? metaImage.content : "";
            const description = getDescription();
            pinterest({ url, description, media });
            break;
        }
        case "whatsapp": {
            whatsapp({ url, title, phone: "" });
            break;
        }
        case "linkedin-in": {
            const description = getDescription();
            linkedin({ url, title, description });
            break;
        }
        case "email": {
            const description = getDescription();
            email({
                to: "",
                url,
                title,
                description,
                subject: "",
            });
            break;
        }
        default:
    }
}

function getDescription() {
    const metaDescription = document.querySelector("meta[property='og:description']");
    const description = metaDescription ? metaDescription.content : "";

    return description;
}

export default { init };
