/* eslint-disable */
import { findDeviceBySerial, getEl } from "./validators";

export const validateAccount = async (config) => {
    //AccountValidationWSRequest
    let { account, serial } = config.query;
    let cwb = account;

    if (!account || !serial) {
        return { error: "no valid query params" };
    }

    let formData = new URLSearchParams();
    formData.append("accountNum", account);
    formData.append("cwbId", cwb);
    formData.append("serialNum", serial);

    const settings = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            "x-sny-siteoverride": config.siteOverride,
        },
        body: formData,
    };

    try {
        let request = await fetch(`${config.contextPath}/.rest/mystar/v1/fr/validateAccount`, settings);
        let response = await request.json();
        let { AccountValidationWSResponseType: code } = response;

        if (code && Number(code) <= 0) {
            return { error: code };
        } else {
            let requestUserData = await fetch(`${config.contextPath}/.rest/mystar/v1/fr/loadUserData`, settings);
            let userResponse = await requestUserData.json();

            let data = userResponse["return"];

            if (!data) {
                return { error: "no return data" };
            }

            if (Number(data.errorCode) < 0) {
                return { error: "error code from API" };
            }
            return data;
        }
    } catch (error) {
        return { error };
    }
};

export const continueRegistration = async (config) => {
    //GuaranteeCarteSaverWSRequest -> GuaranteeCarteSaverWSResponse
    let { account } = config.query;

    let formData = new URLSearchParams();

    Object.keys(config.form.fields).forEach((key) => {
        let v = getEl(key);
        if (key === "deviceType") {
            v = findDeviceBySerial(getEl("serialNumber"));
        }

        if (key === "newsletter") {
            let receive = document.getElementById("receive");
            if (receive.checked) {
                v = true;
            } else {
                v = false;
            }
        }
        formData.append(key, v);
    });
    formData.append("accountNum", account);

    const settings = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            "x-sny-siteoverride": config.siteOverride,
        },
        body: formData,
    };

    try {
        let request = await fetch(`${config.contextPath}/.rest/mystar/v1/fr/completeRegistration`, settings);
        let response = await request.json();
        let { GuaranteeCarteSaverWSResponse } = response;
        if (!GuaranteeCarteSaverWSResponse || Number(GuaranteeCarteSaverWSResponse) <= 0) {
            return { error: "could not complete registration" };
        }
        return response;
    } catch (error) {
        return { error };
    }
};
