window.Alpine.data("toast", (config) => {
    const opts = {
        maxDisplayed: 3,
        duration: 10000,
        ...config,
    };

    return {
        notifications:[], // {toastId:,message:,type:info|warn|error|success,show:true|false}
        style(toast) {
            if(!toast.type) toast.type = "info";
            return {
                'emr-notif-info': toast.type === 'info',
                'emr-notif-error': toast.type === 'error',
                'emr-notif-warn': toast.type === 'warn',
                'emr-notif-success': toast.type === 'success'
            }
        },
        notify(detail, id) {
            detail.id = id;
            detail.handled = false;
            detail.show = true;
            this.notifications.push(detail)
        },
        limitToast() {
            let size = this.notifications.length;
            let i = this.notifications.length;
            while(i--){
                if((size - i) > opts.maxDisplayed) {
                    this.notifications[i].show = false;
                }
            }
        },
        cleanUp() {
            let i = this.notifications.length;
            while(i--){
                if(this.notifications[i] && this.notifications[i].handled) {
                    this.notifications.splice(i, 1);
                }
            }
        },
        exitToast(toast) {
            setTimeout(() => {
                    toast.show=false;
                    toast.handled=true;
                    this.cleanUp();
                }, opts.duration);
        }
    }
});