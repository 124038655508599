import Cookies from "js-cookie";

/* eslint-disable */
export default (config) => {
    const opts = { ...config };
    const P13N_COOKIE = "snfi_pref";
    const REG_COOKIE = "snfi_reg";
    return {
        opts,
        selectCategory(id) {
            const preferences = this.getPreferences();

            if (preferences.categories.indexOf(id) === -1) {
                preferences.categories.push(id);

                Cookies.set(P13N_COOKIE, preferences);
            }
        },
        removeCategory(id) {
            const preferences = this.getPreferences();

            if (preferences.categories.indexOf(id) !== -1) {
                preferences.categories = preferences.categories.filter((elem) => {
                    return elem != id;
                });

                Cookies.set(P13N_COOKIE, preferences);
            }
        },
        getPreferences() {
            let preferences = Cookies.getJSON(P13N_COOKIE);

            if (!preferences) {
                preferences = {
                    categories: [],
                };
            }

            return preferences;
        },
        savePartialRegistration(elem) {
            let partialRegistration = this.getPartialRegistration();
            
            partialRegistration.partialValues[elem.getAttribute('data-schema')] = elem.value;
            
            Cookies.set(REG_COOKIE, partialRegistration);
        },
        getPartialRegistration() {
            let partialRegistration = Cookies.getJSON(REG_COOKIE);

            if (!partialRegistration) {
                partialRegistration = {
                    partialValues: {},
                };
            }

            return partialRegistration;
        },
    };
};
