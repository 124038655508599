/* eslint-disable */
import { initValidation } from "./validation";
import { findDeviceBySerial } from "./validators";

export const initFormFields = (fields) => {
    let f = {};
    Object.keys(fields).forEach((key) => (f[key] = { value: "" }));
    return f;
};

export const initializeForm = (config) => ({
    status: 0,
    fields: { ...initFormFields(config.fields) },
    validation: initValidation(config),

    handleChange(event) {
        let value = event.target.value;
        let id = event.target.id;
        let field = this.fields[id];
        field.value = value;
        if (id === "serialNumber") {
            //test the regexes to find valid device type
            let device = findDeviceBySerial(value);
            this.handleDeviceSelect(device);
        }

        if (id == "newsletter") {
            let receive = document.getElementById("receive");
            let noreceive = document.getElementById("noreceive");

            if (receive.checked) {
                field.value = receive.checked;
            } else if (noreceive.checked) {
                field.value = noreceive.checked;
            } else {
                field.value = "";
            }
        }

        if (!this.fieldIsValid(id)) {
            this.setFieldInvalid(id);
        } else {
            this.setFieldValid(id);
        }
    },

    handleCheck(event) {
        let checked = event.checked;
        this.fields.termsAndConditions.value = checked;
        this.handleChange({ target: { id: "termsAndConditions", value: checked } });
    },

    handleDeviceSelect(id) {
        let devices = document.querySelectorAll(".device-block");

        if (!id) {
            this.fields.deviceType.value = "";
            return;
        }
        this.fields.deviceType.value = id;

        let deviceType = document.getElementById(id);
        devices.forEach((d) => d.classList.remove(`device-block--active`));
        deviceType.classList.add("device-block--active");
    },

    fieldIsValid(id) {
        return this.invalidFormFields.filter((field) => field.key === id).length === 0;
    },

    setFieldInvalid(id) {
        let element = document.getElementById(id);
        if (element) {
            element.classList.add("invalid-input");
            this.addErrorMessages(id);
        }
    },

    setFieldValid(id) {
        let element = document.getElementById(id);
        if (element) {
            element.classList.remove("invalid-input");
            this.removeErrorMessages(id);
        }
    },

    fieldErrorMessages(id) {
        let errorObj = this.invalidFormFields.find((i) => i.key === id);
        if (errorObj) {
            return errorObj.fields.map((f) => f.message);
        }
        return [];
    },

    addErrorMessages(id) {
        let messages = this.fieldErrorMessages(id);
        let errorContainer = document.getElementById("errorMessages");
        messages.forEach((msg) => {
            let p = document.createElement("p");
            p.className = "error";
            p.textContent = msg;
            p.setAttribute("data-error-string", msg);
            p.setAttribute("data-error-field", id);

            let existing = document.querySelector(`[data-error-string="${msg}"]`);
            if (!existing) {
                errorContainer.appendChild(p);
            }
        });
    },

    removeErrorMessages(id) {
        let existing = document.querySelectorAll(`[data-error-field="${id}"]`);
        let errorContainer = document.getElementById("errorMessages");
        if (existing.length > 0) {
            for (let i = 0; i < existing.length; i++) {
                errorContainer.removeChild(existing[i]);
            }
        }
    },

    validateAllFields() {
        this.formValues.forEach((fv) => this.handleChange({ target: { id: fv.key, value: fv.value } }));
    },

    get formValues() {
        return Object.keys(this.fields).map((key) => {
            let field = document.getElementById(key);
            let value = "";
            if (key === "deviceType") {
                field = document.querySelector(".device-block--active");
                if (!field) {
                    value = "";
                } else {
                    value = field.id;
                }
            } else if (key === "newsletter") {
                let receive = document.getElementById("receive");
                let noreceive = document.getElementById("noreceive");

                if (receive.checked) {
                    value = true;
                } else if (noreceive.checked) {
                    value = false;
                }
            }

            return { key, value };
        });
    },

    get invalidFormFields() {
        return this.formValues.map((v) => ({ key: v.key, fields: this.validation[v.key].filter((val) => val.fn() === false) })).filter((v) => v.fields.length !== 0);
    },

    get formIsValid() {
        return this.invalidFormFields.length === 0;
    },

    get buttonText() {
        if (this.status === 1) {
            return config.button.loading;
        } else {
            return config.button.text;
        }
    },
});
