export const showLoading = () => {
    let loading = null,
		overlay = null;

    if (document.querySelector('.emr-loading')) {
		loading = document.querySelector('.emr-loading');
		overlay = document.querySelector('.emr-overlay');
    } else {
		const body = document.querySelector('body');
		loading = document.createElement('div');
		overlay = document.createElement('div');

		loading.classList.add('emr-loading');
		overlay.classList.add('emr-overlay');
		body.appendChild(loading);
		body.appendChild(overlay);
    }
}