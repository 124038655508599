window.Alpine.data("calendarEventDetail", (config) => {
	const opts = {
		...config,
		eventTitle: config.eventTitle,
		eventStartTime: config.eventStartTime,
		eventEndTime: config.eventEndTime,
		eventDescription: config.eventDescription,
		eventLocation: config.eventLocation,
	};

	return {
		eventTitle: opts.eventTitle,
		eventStartTime: opts.eventStartTime,
		eventEndTime: opts.eventEndTime,
		eventDescription: opts.eventDescription,
		eventLocation: opts.eventLocation,

		formatIsoDate(date) {
			// Consume date and convert it to the appropriate ISO format e.g. 20220730T180000Z
			return new Date(date).toISOString().replace(/[-:]/g,"").replace(".000Z", "Z");
		},

		googleIcs() {
			let eventStartTime = this.formatIsoDate(this.eventStartTime);
			let eventEndTime = this.formatIsoDate(this.eventEndTime);
			let eventTitle = encodeURIComponent(this.eventTitle + " | サノフィ株式会社 |");
			let eventLocation = encodeURIComponent(this.eventLocation);
			let eventDetailURL = window.location.href;

			// Formatting has to be this way to avoid unecessary spaces in the output
			let eventDescription = encodeURIComponent("詳細はこちらをご覧ください。 \n\n" + "視聴URL: " + eventDetailURL);

			window.open(`https://www.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${eventStartTime}/${eventEndTime}&details=${eventDescription}&location=${eventLocation}`, "_blank");
		},

		icsDownload() {
			let eventStartTime = this.formatIsoDate(this.eventStartTime);
			let eventEndTime = this.formatIsoDate(this.eventEndTime);
			let eventDetailURL = window.location.href;

			let ics = [
				"BEGIN:VCALENDAR",
				"VERSION:2.0",
				"BEGIN:VEVENT",
				"URL:" + "",
				"DTSTART:" + eventStartTime,
				"DTEND:" + eventEndTime,
				"SUMMARY:" + this.eventTitle + " | サノフィ株式会社 |",
				"DESCRIPTION:" + "詳細はこちらをご覧ください。\\n\\n視聴URL: " + eventDetailURL,
				"LOCATION:" + this.eventLocation,
				"END:VEVENT",
				"END:VCALENDAR"
			].join("\n");
			let file = new Blob([...ics], {type: "text/html"});
			let fileName = "calendarEvent.ics";

			if (window.navigator.msSaveOrOpenBlob) { 
				// IE10+
				window.navigator.msSaveOrOpenBlob(file, fileName);
			} else { 
				// Modern Browsers
				let a = document.createElement("a"),
					url = URL.createObjectURL(file);
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				setTimeout(function() {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);  
				}, 0); 
			}
		}
	};
});
