window.Alpine.data("emrProductMediaPanel", (config) => {
    const opts = {expanded: false, numOfItemsToDisplay: 2, totalItem: 0, productMediaItemsWrapperClass: "", ...config};
    return {
        expanded: opts.expanded,
        numOfItemsToDisplay: opts.numOfItemsToDisplay,
        totalItem: opts.totalItem,
        productMediaItemsWrapperClass: opts.productMediaItemsWrapperClass,
        initProductMediaPanel(numOfItemsToDisplay = 5, totalItems = 0, productMediaItemsWrapperClass = ""){
            this.numOfItemsToDisplay = !isNaN(numOfItemsToDisplay) ? parseInt(numOfItemsToDisplay) : 5;
            this.totalItem = !isNaN(totalItems) ? parseInt(totalItems) : 0;
            this.productMediaItemsWrapperClass = productMediaItemsWrapperClass;
            if(this.totalItem > this.numOfItemsToDisplay){
                const productMediaItemSelectors = document.querySelectorAll(`.${this.productMediaItemsWrapperClass} .productMediaItem__wrapper`);
                for(let index = 0; index < productMediaItemSelectors.length; index++){
                    if(index > this.numOfItemsToDisplay - 1){
                        productMediaItemSelectors[index].style.display="none"
                    }
                }
            }
        },
        expand(){
            if(this.totalItem <= this.numOfItemsToDisplay) return;
            const productMediaItemSelectors = document.querySelectorAll(`.${this.productMediaItemsWrapperClass} .productMediaItem__wrapper`);
            for(let index = 0; index < productMediaItemSelectors.length; index++){
                if(!this.expanded && index > this.numOfItemsToDisplay - 1){
                    productMediaItemSelectors[index].style.display="block";
                }else if(this.expanded && index > this.numOfItemsToDisplay - 1){
                    productMediaItemSelectors[index].style.display="none";
                    document.querySelector(`.${this.productMediaItemsWrapperClass}`).scrollIntoView();
                }
            }
            this.expanded = !this.expanded;
        }
    };
});
