/**
 * Default page javascript extended from sanofi-lm-platform.
 * All other pages in sanofi-lm-emr extend this one.
 */

import BasePage from "platform/src/js/modules/basePage";

// Styles
import "../../css/pages/BasePage.scss";

// Libs
import "./sui/components";
import pageInNav from "./pageInNav";
import medicalModal from "./medicalModal";
import greetings from "./greetings";

class EmrBasePage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
        pageInNav.init();
        medicalModal.init();
        greetings.init();
    }

    onReady() {
        super.onReady();
    }
}

export default EmrBasePage;
